import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import { useAuth } from "../contexts/AuthContext";
import axios from 'axios';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
import { Delete, Download } from '@mui/icons-material';

export default function UserVideos() {
    const [videos,setVideos] = useState([])
    const {currentUser} = useAuth()
    // const baseUrl = "https://myvideoplayer.tk:4000/"
    const baseUrl = "http://localhost:4000/"
    // useEffect(()=>{
    //     videos.forEach((each)=>{
    //         console.log(each)
    //     })
    //     },[videos])
    useEffect(()=>{
        axios({method: "get",
          // url:'http://myvideoplayer.tk/save', 
          // url:'http://localhost:5000/video_list?user=' + currentUser.email}).then(async (res) => {
          url:'http://myvideoplayer.tk/video_list?user=' + currentUser.email}).then(async (res) => {
            //Now do what you want with the response;
            console.log(res)
            setVideos(res.data.videos)
            
          }).catch(function (e) {
            //handle error
            console.log(e);
            // alert('Render failed')
        })
    },[])
    

    // var videos = [ 
    //     { "description" : "Big Buck Bunny tells the story of a giant rabbit with a heart bigger than himself. When one sunny day three rodents rudely harass him, something snaps... and the rabbit ain't no bunny anymore! In the typical cartoon tradition he prepares the nasty rodents a comical revenge.\n\nLicensed under the Creative Commons Attribution license\nhttp://www.bigbuckbunny.org",
    //       "sources" : [ "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" ],
    //       "subtitle" : "By Blender Foundation",
    //       "thumb" : "images/BigBuckBunny.jpg",
    //       "title" : "Big Buck Bunny"
    //     },
    //     { "description" : "The first Blender Open Movie from 2006",
    //       "sources" : [ "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4" ],
    //       "subtitle" : "By Blender Foundation",
    //       "thumb" : "images/ElephantsDream.jpg",
    //       "title" : "Elephant Dream"
    //     },
    //     { "description" : "HBO GO now works with Chromecast -- the easiest way to enjoy online video on your TV. For when you want to settle into your Iron Throne to watch the latest episodes. For $35.\nLearn how to use Chromecast with HBO GO and more at google.com/chromecast.",
    //       "sources" : [ "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4" ],
    //       "subtitle" : "By Google",
    //       "thumb" : "images/ForBiggerBlazes.jpg",
    //       "title" : "For Bigger Blazes"
    //     },
    //     { "description" : "Introducing Chromecast. The easiest way to enjoy online video and music on your TV—for when Batman's escapes aren't quite big enough. For $35. Learn how to use Chromecast with Google Play Movies and more at google.com/chromecast.",
    //       "sources" : [ "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4" ],
    //       "subtitle" : "By Google",
    //       "thumb" : "images/ForBiggerEscapes.jpg",
    //       "title" : "For Bigger Escape"
    //     }];
    return (
    <div>
        <Typography variant="h4">My Videos</Typography>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
            {videos && videos.map((vid)=>{
                return(
                    <Grid
                            item
                            md={4}
                            style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            }}
                        >
                    <Card sx={{ maxWidth: 345 }}>
                        <CardContent>
                            <video controls crossOrigin="anonymous" width={250} src={baseUrl + "video/"+currentUser.email+"/"+vid}/>
                            <Typography variant="body2" color="text.secondary">
                            {vid}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <EmailShareButton children={<EmailIcon size={32} round={true} />} url={baseUrl + "video/"+currentUser.email+"/"+vid}/>
                            <FacebookShareButton children={<FacebookIcon size={32} round={true} />} url={"https://www.youtube.com/watch?v=C0DPdy98e4c"}/>
                            <TwitterShareButton children={<TwitterIcon size={32} round={true} />} url={baseUrl + "video/"+currentUser.email+"/"+vid}/>
                            <WhatsappShareButton children={<WhatsappIcon size={32} round={true} />} url={baseUrl + "video/"+currentUser.email+"/"+vid}/>
                            <TelegramShareButton children={<TelegramIcon size={32} round={true} />} url={baseUrl + "video/"+currentUser.email+"/"+vid}/>
                            {/* <Button size="small"><Download/></Button>
                            <Button size="small"><Delete/></Button> */}
                        </CardActions>
                        </Card>
                        </Grid>
                
                )
            })}
            </Grid>
        </Box>
        
    </div>
  )
}
