import React,  { useState, useRef, useCallback, useEffect } from "react";
import ReactDOM from 'react-dom';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
// import "./HomePage.css";
import { Stage, Layer } from "react-konva";
import Slider from '@mui/material/Slider';
import Rectangle from "../canvas/Rectangle";
import Circle from "../canvas//Circle";
import { addLine } from "../canvas//line";
import { addTextNode } from "../canvas//textNode";
import {default as CanvasImage} from "../canvas/Image";
import Video from "../canvas/Video";
import AudioTrack from "./AudioTrack";
import { v1 as uuidv1 } from 'uuid';
import Konva from "konva";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LoopIcon from '@mui/icons-material/Loop';
import Slide from '@mui/material/Slide';
import 'grapesjs-preset-webpage';
import { GrapesjsReact } from "grapesjs-react";
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
// import 'gjs-blocks-basic'
import 'grapesjs-blocks-bootstrap4'
import lion from '../assets/lion2.gif'
import filter_blur from '../assets/filter_blur.png'
import filter_contrast from '../assets/filter_contrast.png'
import filter_grey from '../assets/filter_grey.png'
import filter_hue from '../assets/filter_hue.png'
import filter_hue2 from '../assets/filter_hue2.png'
import filter_hue3 from '../assets/filter_hue3.png'
import filter_hue4 from '../assets/filter_hue4.png'
import filter_inv from '../assets/filter_inv.png'
import filter_none from '../assets/filter_none.png'
// import { toPng } from 'html-to-image';
// import { Invert } from "konva/lib/filters/Invert";
import html2canvas from 'html2canvas'
// import domtoimage from 'dom-to-image';
import TimeRange from 'react-timeline-range-slider'
import { endOfToday, set } from 'date-fns' 
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { SketchPicker } from 'react-color';
import { Hook, Console, Decode } from 'console-feed';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import moment from "moment";
import axios from 'axios';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UndoIcon from '@mui/icons-material/Undo';
import TrackContainer from './TrackContainer'
import { useAuth } from "../contexts/AuthContext";
// import 'ccapture.js'
// import { CanvasCapture } from 'canvas-capture';

// const uuidv1 = require("uuid/v1");
function HomePage(props) {
  // console.log('HomePage render')
  const [rectangles, setRectangles] = useState([]);
  const [circles, setCircles] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [shapes, setShapes] = useState([]);
  const [, updateState] = React.useState();
  const stageEl = React.createRef();
  const layer0El = React.createRef();
  const layerEl = React.createRef();
  const layer2El = React.createRef();
  const fileUploadEl = React.createRef();
  const videoTimelineEl = React.createRef();
  const videoEl = React.useRef(null);
  const sliderEl = React.useRef(null);
  const [loop_state, setLoopState] = useState(false);
  const [sliderVal, setSliderVal] = useState(0);
  const [currentFrameTime, setCurrentFrameTime] = useState(1);
  const [currentFrameTimeRef, setCurrentFrameTimeRef] = useState(1);
  const [startTimeSec, setStartTimeSec] = useState(0);
  const [stopTimeSec, setStopTimeSec] = useState(0);
  const [startAudioTimeSec, setStartAudioTimeSec] = useState(0);
  const [stopAudioTimeSec, setStopAudioTimeSec] = useState(0);
  const [buttontype, setButtontype] = useState("secondary");
  const [open, setOpen] = React.useState(false);
  const [addType, setAddType] = React.useState('button');
  const [scroll, setScroll] = React.useState('paper');
  const [filterValue, setFilterValue] = React.useState('none');
  const [selectedTool,setSelectedTool] = useState('none');
  const [audio, setAudio] = useState();
  const [log,setLog] = useState();
  const [renderFormat,setRenderFormat] = useState('mp4')
  const [width, setWidth] = useState(window.innerWidth);
  const [openTool, setOpenTool] = useState(false)
  const [playback,setPlayBack] = useState("stop")
  const [animplayback,setAnimplayBack] = useState("stop")
  const [timeStamp,setTimeStamp] = useState("00:00:00")
  const [stageFullscreen,setStageFullscreen] = useState(false)
  const [stageDim,setStageDim] = useState(props.stageDim1_)
  const [sharevalue,setSharevalue] = useState('')
  const [muteVideo,setMuteVideo] = useState(false)
  const [tabvalue, setTabvalue] = React.useState(0);
  const [cutPoints, setCutPoints] = React.useState([]);
  const [cutPointSec, setCutPointSec] = React.useState([]);
  const [frames, setFrames] = React.useState();
  const [fileLenghts, setFileLenghts] = React.useState();
  const [fileItems, setfileItems] = React.useState([]);
  const [animradio, setAnimradio] = React.useState("0");

  const {currentUser} = useAuth()

  const handleTabChange = (event, newValue) => {
    setTabvalue(newValue);
  };
  const [textStyle, setTextStyle] = useState({
    color:'black',
    backgroundColor:'rgba(0,0,0,0)',
    textDecorationLine: 'none',
    letterSpacing: '5px',
    fontFamily: 'Copperplate',
    fontStyle: 'normal',
    fontSize: '40px',
    fontWeight:'100'
  })

  var downloadUrl = null;
  var animFlag1 = false;

  const [animFile,setanimFile] = useState(null);
  const [animFlag2,setanimFlag2] = useState(false);
  const [layeref,setlayeref] = useState(null);

  // Create a capturer that exports a WebM video
  // var capturer = new CCapture( { format: 'webm' } );

  var fileLen_ = {}

  var timelineItems = []

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  async function extractFramesFromVideo(videoUrl, fps=25) {
    return new Promise(async (resolve) => {

      // fully download it first (no buffering):
      let videoBlob = await fetch(videoUrl).then(r => r.blob());
      let videoObjectUrl = URL.createObjectURL(videoBlob);
      let video = document.createElement("video");

      let seekResolve;
      video.addEventListener('seeked', async function() {
        if(seekResolve) seekResolve();
      });

      video.src = videoObjectUrl;

      // workaround chromium metadata bug (https://stackoverflow.com/q/38062864/993683)
      while((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {
        await new Promise(r => setTimeout(r, 1000));
        video.currentTime = 10000000*Math.random();
      }
      let duration = video.duration;

      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      let [w, h] = [video.videoWidth, video.videoHeight]
      canvas.width =  w;
      canvas.height = h;

      let frames = [];
      let interval = fps / fps;
      let currentTime = 0;

      while(currentTime < duration) {
        video.currentTime = currentTime;
        await new Promise(r => seekResolve=r);

        context.drawImage(video, 0, 0, w, h);
        let base64ImageData = canvas.toDataURL();
        frames.push(base64ImageData);

        currentTime += interval;
      }
      resolve(frames);
    });
  };

  var EditorInstance = null;
  const stageWidth = window.innerWidth* (2/3) * 0.9;
  const stageHeight = window.innerWidth* (2/3) * 0.9 * 0.5;

  const ffmpeg = props.ffmpeg_

  var now = new Date()

  const getTodayAtSpecificHour = (hour = 12, minute = 0, second = 0) =>
      set(now, { hours: hour, minutes: minute, seconds: second, milliseconds: 0 })

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFilter = (e) => {
      setFilterValue(e.target.value);
      // console.log(e.target.value)
      const canvas = layer0El.current.getCanvas()._canvas;
      canvas.style.filter = e.target.value;
  }

  function downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const [openEditor, setOpenEditor] = React.useState(false);

  const handleClickOpenEditor = () => {
    // Hook(window.console, log => {
    //   setLog([...log, Decode(log)]);
    // })
    setOpenEditor(true);
  };

  const handleCloseEditor = () => {
    setOpenEditor(false)
  };


  var lineInstance = null;
  const getRandomInt = max => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  const selectedStart = getTodayAtSpecificHour(0,0,0)
  const startTime = getTodayAtSpecificHour(0,0,0)
  var selectedEnd = getTodayAtSpecificHour(0,30,0)
  var endTime = getTodayAtSpecificHour(1,0,0)

  const [timelineInterval,setTimelineInterval] = useState([startTime,endTime])
  const [selectedInterval, setSelectedInterval] = useState([selectedStart,selectedEnd]);
  

  const videoElement = document.getElementById("video");
  

  var formData2 = null;
  var frames_;
  

  useEffect(()=>{
    videoElement.currentTime = currentFrameTime;
    setTimeStamp(moment.utc((currentFrameTime - startTimeSec)*1000).format('HH:mm:ss'));
    // console.log('currentTime',currentFrameTime, startTimeSec)
    // console.log('currentTimeRef',currentFrameTimeRef)
    // setCurrentFrameTimeRef(currentFrameTime)
    // setSliderVal(Math.floor(currentFrameTime - startTimeSec));
    // fileLen_[props.video_.name] = videoElement.duration
    // setFileLenghts(fileLen_)
  },[currentFrameTime])

  // useEffect(()=>{
  //   fileLen_[props.video_.name] = videoElement.duration
  //   setFileLenghts(fileLen_)
  //   setfileItems([props.video_,audio])
  // },[])

  const setCurrentTime = (time) =>{
    setTimeStamp(moment.utc((time - startTimeSec)*1000).format('HH:mm:ss'));
    setSliderVal(Math.floor(time - startTimeSec));
    setCurrentFrameTimeRef(time)
    videoElement.currentTime = time;
  }

  const setCurrentTime2 = (time) =>{
    setCurrentFrameTime(time)
    setCurrentFrameTimeRef(time)
    videoElement.currentTime = time;
    setTimeStamp(moment.utc((time - startTimeSec)*1000).format('HH:mm:ss'));
  }

  const setStageDimension = (dim)=>{
    setStageDim(dim)
  }

  useEffect(()=>{
    
    if(width <= 768){
      setStageDim([window.innerWidth* 0.9 ,window.innerWidth* 0.9 * 0.5])
    }else{
      setStageDim([window.innerWidth* (2/3) * 0.9 ,window.innerWidth* (2/3) * 0.9 * 0.5])
    }
    videoElement.src = URL.createObjectURL(props.video_);
    videoElement.currentTime = currentFrameTime;
    // console.log(stageDim)

    var metaLoadbool = false

    async function metaLoad(){
      if(!metaLoadbool){
        console.log('metadata loaded!',videoElement.videoWidth);
        // console.log(videoElement.duration);//this refers to myVideo
        const MINUTES = Math.floor(videoElement.duration)
        var m = MINUTES % 60;
        var h = (MINUTES-m)/60;
        setStopTimeSec(videoElement.duration)
        selectedEnd = getTodayAtSpecificHour(h,m,0)
        endTime = getTodayAtSpecificHour(h,m,0)
        setTimelineInterval([startTime,endTime])
        setSelectedInterval([selectedStart,selectedEnd])

        frames_ = await extractFramesFromVideo(URL.createObjectURL(props.video_));
        // console.log(frames)
        setFrames(frames_)
        fileLen_[props.video_.name] = MINUTES
        setFileLenghts(fileLen_)
        setfileItems([props.video_,audio])
      }
      
      
      metaLoadbool = true
      this.removeEventListener('loadeddata',metaLoad, true)
      return
    }

    videoElement.addEventListener('loadeddata', metaLoad, true);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
    
  },[width])

  const isMobile = width <= 768;
  
  useEffect(()=>{
    // console.log('play',playback)
    setAnimplayBack(playback)
  },[playback])

  const handleSliderChange = (event, newValue) => {
    
    if(isFinite(newValue)){
      // const videoDuration = videoElement.duration;
      // setCurrentFrameTime(parseInt(1 + (videoDuration * (newValue/50))));
      setCurrentFrameTime(parseInt(startTimeSec) + parseInt(newValue))
      setCurrentFrameTimeRef(parseInt(startTimeSec) + parseInt(newValue))
      setSliderVal(newValue);
      // console.log('slider',startTimeSec, newValue)
      // console.log(currentFrameTime+" "+newValue+" "+videoDuration);
    }
    // if (newValue == null || isNaN(newValue) || sliderEl == null){
    //     return
    // }
    
    
  };

  const addRectangle = () => {
    setSelectedTool('rectangle')
    layer2El.current.hide()
    const rect = {
      x: getRandomInt(100),
      y: getRandomInt(100),
      width: 100,
      height: 100,
      fill: "red",
      id: `rect${rectangles.length + 1}`,
    };
    const rects = rectangles.concat([rect]);
    setRectangles(rects);
    const shs = shapes.concat([`rect${rectangles.length + 1}`]);
    setShapes(shs);
  };
  const addCircle = () => {
    setSelectedTool('circle')
    layer2El.current.hide()
    const circ = {
      x: getRandomInt(100),
      y: getRandomInt(100),
      width: 100,
      height: 100,
      fill: "red",
      id: `circ${circles.length + 1}`,
    };
    const circs = circles.concat([circ]);
    setCircles(circs);
    const shs = shapes.concat([`circ${circles.length + 1}`]);
    setShapes(shs);
  };
  const drawLine = () => {
    setSelectedTool('line')
    layer2El.current.show()
    layer2El.current.destroyChildren()
    addLine(stageEl.current.getStage(), layer2El.current);
  };
  const eraseLine = () => {
    setSelectedTool('eraser')
    // layer2El.current.hide()
    // layer2El.current.destroyChildren()
    lineInstance = addLine(stageEl.current.getStage(), layer2El.current, "erase");
  };
  const drawText = () => {
    setSelectedTool('text')
    layer2El.current.hide()
    // setOpenEditor(true);
    // handleClickOpenEditor();
    // const id = addTextNode(stageEl.current.getStage(), layerEl.current);
    // const shs = shapes.concat([id]);
    // setShapes(shs);
  };
  const addButton = () => {
    setSelectedTool('button')
    layer2El.current.hide()
    // setOpenEditor(true);
    // handleClickOpenEditor();
    // const id = addTextNode(stageEl.current.getStage(), layerEl.current);
    // const shs = shapes.concat([id]);
    // setShapes(shs);
  };
  const drawImage = () => {
    setSelectedTool('image')
    layer2El.current.hide()
    fileUploadEl.current.click();
  };
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const fileChange = ev => {
    let file = ev.target.files[0];
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const id = uuidv1();
        images.push({
          content: reader.result,
          srcType: 'url',
          draggable_:true,
          id,
          animation: {}
        });
        setImages(images);
        fileUploadEl.current.value = null;
        shapes.push(id);
        setShapes(shapes);
        forceUpdate();
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const addShape = ()=>{
    setSelectedTool('add');
  }
  const undo = () => {
    
    layer2El.current.destroyChildren();
    layer2El.current.hide()
    const lastId = shapes[shapes.length - 1];
    let index = circles.findIndex(c => c.id == lastId);
    if (index != -1) {
      circles.splice(index, 1);
      setCircles(circles);
    }
    index = rectangles.findIndex(r => r.id == lastId);
    if (index != -1) {
      rectangles.splice(index, 1);
      setRectangles(rectangles);
    }
    index = images.findIndex(r => r.id == lastId);
    if (index != -1) {
      images.splice(index, 1);
      setImages(images);
    }
    shapes.pop();
    setShapes(shapes);
    forceUpdate();
  };

  const loop = (e) => {
    setLoopState(!loop_state);
    if(!loop_state){
        setButtontype("primary");
        // e.target.className = "btn btn-primary";
    }else{
        setButtontype("secondary");
        // e.target.className = "btn btn-secondary";
    }
    // console.log(e)
  }

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }
  const renderClick = () => {
    setSelectedTool('render')
  }

  async function blobToDataURL(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.onabort = () => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
    });
}

  const filterVals = {
    "none":"none",
    "invert()": 'negate',
    "blur(5px)": 'boxblur=luma_radius=2:luma_power=1',
    "contrast(200%)":'colorcontrast=rc=0.5:gm=0.5:by=0.5',
    "grayscale(100%)": 'format=gray',
    "hue-rotate(90deg)": 'hue=h=90:s=2',
    "hue-rotate(45deg)": 'hue=h=45:s=2',
    "hue-rotate(180deg)": 'hue=h=180:s=2',
    "hue-rotate(270deg)": 'hue=h=270:s=2',
    "url(#teal-white)":'colorize=hue=180:saturation=1:lightness=0.5:mix=0.6'
  }

  const render = async (ref) => {
    layerEl.current = ref
    
    selectShape(null)
    const toTime = moment.utc(stopTimeSec*1000).format('HH:mm:ss');
    const fromTime = moment.utc(startTimeSec*1000).format('HH:mm:ss');
    // console.log('startAudioTimeSec',startAudioTimeSec,'stopAudioTimeSec',stopAudioTimeSec)
    var count = 0;
    videoElement.src = URL.createObjectURL(props.video_);
    videoElement.load();
    var intervalFn = setInterval(async function () {
      count++;
      if(count>20){
        clearInterval(intervalFn);
        console.log("render timeout")
        console.log(layerEl.current)
      }
      if(layerEl.current != null && videoElement.videoWidth > 0){
        if(animFlag2){
          layerEl.current.destroyChildren()
        }
        console.log("render called")
        const scaleX = stageDim[0] / videoElement.videoWidth;
        const scaleY = stageDim[1] / videoElement.videoHeight;
        var scale = Math.max(scaleX, scaleY)
        clearInterval(intervalFn);
        // const uri = layerEl.current.toDataURL();
        console.log('getdata',videoElement.videoWidth,Math.floor(videoElement.videoWidth*scale),Math.floor(videoElement.videoHeight*scale))
        const ctx3 = layerEl.current.getContext();
        const tmpuri = ctx3.getImageData(0, 0, Math.floor(videoElement.videoWidth*scale), Math.floor(videoElement.videoHeight*scale));
        // handleClickOpenEditor()
        var canvas = document.getElementById('testcanvas');
        var ctx = canvas.getContext('2d');
        canvas.width = Math.floor(videoElement.videoWidth*scale);
        canvas.height = Math.floor(videoElement.videoHeight*scale);
        ctx.putImageData(tmpuri,0,0)
        const uri = canvas.toDataURL()
        // downloadURI(uri,'test.png')
        if(cutPointSec.length > 0){
          var vf = "select='";
          cutPointSec.map((each,i)=>{
            if(i < cutPointSec.length - 1){
              vf = vf + `between(t,${each[0]},${each[1]})+`
            }else{
              vf = vf + `between(t,${each[0]},${each[1]})',setpts=N/FRAME_RATE/TB`
            }
            
          })
          // console.log('vf',vf)
        }
        

        if(props.ffmpegFail_){
          
          const formData = new FormData();
          formData.append('file', props.video_);
          formData.append('audio_file', audio);
          formData.append('image_data', uri);
          formData.append('to_time', toTime);
          formData.append('from_time', fromTime);
          formData.append('filter_data',''+filterVals[filterValue]);
          formData.append('render_format', renderFormat);
          formData.append('video_width', videoElement.videoWidth);
          formData.append('video_height', videoElement.videoHeight);
          formData.append('scale', scale);
          formData.append('startAudioTimeSec', startAudioTimeSec);
          formData.append('stopAudioTimeSec', stopAudioTimeSec);
          formData.append("user",currentUser.email)
          if(animFlag2){
            formData.append("anim_file",animFile)
          }
          
          // console.log(props.video_,audio,uri)
          handleClickOpenEditor()

          const config = {method: "POST",
          url:'http://myvideoplayer.tk/render', 
          // url:'http://localhost:5000/render', 
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
          responseType: 'blob'}

          axios(config).then(async (res) => {
            //Now do what you want with the response;
            // console.log(res)
            const thumbnailBlob = await res.data;
            const thumbnail = await blobToDataURL(thumbnailBlob);
            downloadUrl = thumbnail
            const videoout = document.getElementById('render_output')
            videoout.src = downloadUrl
            videoout.style.display = 'block'
            document.getElementById('progressbars').style.display = 'none'
            // document.getElementById('render_console').style.display = 'none'
            downloadURI(thumbnail, 'out.'+renderFormat);
            // handleCloseEditor();
            return res
          }).catch(function (e) {
            //handle error
            // console.log('error',e);
            handleCloseEditor()
            alert('Render failed')
        })
        }else{
          handleClickOpenEditor()
          ffmpeg.FS('writeFile','test.mp4', await props.fetchFile_(props.video_));
        ffmpeg.FS('writeFile','audio.mp3', await props.fetchFile_(audio));
        if(animFile){
          ffmpeg.FS('writeFile','anim.mp4', await props.fetchFile_(animFile))
        }
        // console.log(filterValue)
        try{
          if(filterValue != 'none' && audio && renderFormat == 'gif'){
          await ffmpeg.run('-i', 'test.mp4','-ss','00:00:00','-to',toTime,'-vf', filterVals[filterValue],'tmp.mp4');
          await ffmpeg.run('-i', 'tmp.mp4', '-i', `${uri}`,'-ss',fromTime,'-to',toTime,
          '-filter_complex', `[1:v]scale=${videoElement.videoWidth}:${videoElement.videoHeight} [ovrl],[0:v][ovrl] overlay=0:0`
          , 'out.'+renderFormat);
        }else if(filterValue == 'none' && audio && renderFormat == 'gif'){
          await ffmpeg.run('-i', 'test.mp4', '-i', `${uri}`,'-ss',fromTime,'-to',toTime,
          '-filter_complex', `[1:v]scale=${videoElement.videoWidth}:${videoElement.videoHeight} [ovrl],[0:v][ovrl] overlay=0:0`
          , 'out.'+renderFormat);
        }else if(filterValue != 'none' && audio){
          await ffmpeg.run('-i', 'test.mp4','-ss','00:00:00','-to',toTime,'-vf', filterVals[filterValue],'tmp.mp4');
          await ffmpeg.run('-i', 'tmp.mp4', '-i', `${uri}`,'-i','audio.mp3', '-ss',fromTime,'-to',toTime,
          '-filter_complex', `[1:v]scale=${videoElement.videoWidth}:${videoElement.videoHeight} [ovrl],[0:v][ovrl] overlay=0:0;[2:a]atrim=start=${parseInt(startAudioTimeSec)}:end=${parseInt(stopAudioTimeSec)},asetpts=PTS-STARTPTS [a0]`
          ,'-map', '0:v', '-map', '[a0]', 'out.'+renderFormat);
        }else if(filterValue == 'none' && audio){
          await ffmpeg.run('-i', 'test.mp4', '-i', `${uri}`,'-i','audio.mp3', '-ss',fromTime,'-to',toTime,
          '-filter_complex', `[1:v]scale=${videoElement.videoWidth}:${videoElement.videoHeight} [ovrl],[0:v][ovrl] overlay=0:0;[2:a]atrim=start=${parseInt(startAudioTimeSec)}:end=${parseInt(stopAudioTimeSec)},asetpts=PTS-STARTPTS [a0]`
          ,'-map', '0:v', '-map', '[a0]', 'out.'+renderFormat);
        }else if(filterValue != 'none'){
          await ffmpeg.run('-i', 'test.mp4','-ss','00:00:00','-to',toTime,'-vf', filterVals[filterValue],'tmp.mp4');
          await ffmpeg.run('-i', 'tmp.mp4', '-i', `${uri}`,'-ss',fromTime,'-to',toTime,
          '-filter_complex', `[1:v]scale=${videoElement.videoWidth}:${videoElement.videoHeight} [ovrl],[0:v][ovrl] overlay=0:0`
          , 'out.'+renderFormat);
        }else{
          await ffmpeg.run('-i', 'test.mp4', '-i', `${uri}`,'-ss',fromTime,'-to',toTime,
          '-filter_complex', `[1:v]scale=${videoElement.videoWidth}:${videoElement.videoHeight} [ovrl],[0:v][ovrl] overlay=0:0`
          , 'out.'+renderFormat);
        }
        var data = null;
        if(animFile){
          console.log("animfile loaded")
          // overlay the animation
          // await ffmpeg.run('-i', 'out.'+renderFormat, '-i', 'anim.mp4','-report', '-filter_complex',
          //  "[1][0]scale2ref[1][0]")
          await ffmpeg.run('-i', 'out.'+renderFormat, '-i', 'anim.mp4', '-filter_complex',
           "[1:v][0:v]scale2ref[v1][v0];[v1]colorkey=0x000000:0.3:0.2[ckout];[0:v][ckout]overlay[out]",
            '-map', '[out]', 'out1.'+renderFormat,'-map', '[v0]','temp.mp4')
          data = ffmpeg.FS('readFile','out1.'+renderFormat);
          // await ffmpeg.run('-i', 'out.'+renderFormat,'-i', 'anim.mp4',
          // '-filter_complex',
          // `[1]split[m][a];
          //  [a]format=yuv444p,geq='if(gt(lum(X,Y),0),255,0)',hue=s=0[al];
          //  [m][al]alphamerge[ovr];
          //  [0][ovr]overlay=(main_w-overlay_w):main_h-overlay_h[v]`,
          // '-map', "[v]", '-c:v', 'libx264', '-r', '25', 'out1.'+renderFormat);
          // await ffmpeg.run('-i', 'out.'+renderFormat, '-i', 'anim_overlay.mp4', '-filter_complex', 
          // "[0:0][1:0]overlay[out]", '-shortest', '-map', '[out]', '-map', '0:1', '-pix_fmt',
          //  'yuv420p', '-c:a', 'copy', '-c:v', 'libx264', '-crf', '18',  'out.'+renderFormat);
        }else{
          data = ffmpeg.FS('readFile','out.'+renderFormat);
        }
          
          
          // handleCloseEditor()
          
          
          var url;
          formData2 = new FormData();
          // console.log(data.buffer)
          // console.log(typeof data.buffer)
          
          if(renderFormat == 'mp4'){
            url = URL.createObjectURL(new Blob([data.buffer],{type:"video/mp4"}))
            formData2.append('file', new Blob([data.buffer],{type:"video/mp4"},'out.mp4'),'out.mp4');
          }else{
            url = URL.createObjectURL(new Blob([data.buffer],{type:"image/gif"}))
            formData2.append('file', new Blob([data.buffer],{type:"video/mp4"}),'out.gif');
          }
          formData2.append("user",currentUser.email)
          downloadUrl = url;
          downloadURI(url,'out.'+renderFormat)
          if(renderFormat == 'mp4'){
            const videoout = document.getElementById('render_output')
            videoout.src = url
            videoout.style.display = 'block'
          }else{
            const gifOut = document.getElementById('render_output_gif')
            gifOut.src = url
            gifOut.style.display = 'block'
          }
          
          document.getElementById('progressbars').style.display = 'none'
          // document.getElementById('render_console').style.display = 'none'
          // document.getElementById('render_finish').style.display = 'flex'
          // document.getElementById('download-btn').style.display = 'flex'
          
          axios({method: "post",
          url:'http://myvideoplayer.tk/save', 
          // url:'http://localhost:5000/save', 
          data: formData2,
          headers: { "Content-Type": "multipart/form-data" }}).then(async (res) => {
            //Now do what you want with the response;
            // console.log(res)
            
          }).catch(function (e) {
            //handle error
            // console.log(e);
            handleCloseEditor()
            alert('Saving failed')
          })
          
          // downloadURI(url, 'out.'+renderFormat);
        }catch(error){
          console.error(error)
          handleCloseEditor()
          alert('Render failed')
        }
        }

        
        
      }
      // console.log('interval')
    }, 1000);
    
    // setTimeout(async ()=>{
    //   const uri = layerEl.current.toDataURL();
    //   handleClickOpenEditor()
    //   ffmpeg.FS('writeFile','test.mp4', await props.fetchFile_(props.video_));
    //   ffmpeg.FS('writeFile','audio.mp3', await props.fetchFile_(audio));
    //   // console.log(filterValue)
    //   try{
    //     if(filterValue != 'none' && audio){
    //       await ffmpeg.run('-i', 'test.mp4','-ss','00:00:00','-to',toTime,'-vf', filterVals[filterValue],'tmp.mp4');
    //       await ffmpeg.run('-i', 'tmp.mp4', '-i', `${uri}`,'-i','audio.mp3', '-ss',fromTime,'-to',toTime,
    //       '-filter_complex', "[0:v][1:v] overlay=0:0:enable='between(t,0,60)'"
    //       ,'-map', '0:v', '-map', '2:a', 'out.mp4');
    //     }else if(filterValue == 'none' && audio){
    //       await ffmpeg.run('-i', 'test.mp4', '-i', `${uri}`,'-i','audio.mp3', '-ss',fromTime,'-to',toTime,
    //       '-filter_complex', "[0:v][1:v] overlay=0:0:enable='between(t,0,60)'"
    //       ,'-map', '0:v', '-map', '2:a', 'out.mp4');
    //     }else if(filterValue != 'none'){
    //       await ffmpeg.run('-i', 'test.mp4','-ss','00:00:00','-to',toTime,'-vf', filterVals[filterValue],'tmp.mp4');
    //       await ffmpeg.run('-i', 'tmp.mp4', '-i', `${uri}`,'-ss',fromTime,'-to',toTime,
    //       '-filter_complex', "[0:v][1:v] overlay=0:0:enable='between(t,0,60)'"
    //       , 'out.mp4');
    //     }else{
    //       await ffmpeg.run('-i', 'test.mp4', '-i', `${uri}`,'-ss',fromTime,'-to',toTime,
    //       '-filter_complex', "[0:v][1:v] overlay=0:0:enable='between(t,0,60)'"
    //       , 'out.mp4');
    //     }
        
    //     const data = ffmpeg.FS('readFile','out.mp4');
    //     handleCloseEditor()
    //     const url = URL.createObjectURL(new Blob([data.buffer],{type:"video/mp4"}))
    //     downloadURI(url, 'out.mp4');
    //   }catch{
    //     handleCloseEditor()
    //   }
      
    //   // var file = dataURLtoFile(uri,'out.png');
    //   // console.log(file);
    //   // console.log(uri);
    //   // downloadURI(uri, 'stage.png');
    //   // var base64Data = uri.replace(/^data:image\/png;base64,/, "");
    //   // require("fs").writeFile("out.png", base64Data, 'base64', function(err) {
    //   //   console.log(err);
    //   // });
    //   // var image = stageEl.current.toImage({
    //   //   callback(img) {
    //   //     // do stuff with img
    //   //     console.log(img)
    //   //   }
    //   // });
    //   // layer2El.current.destroyChildren();
    //   // layerEl.current.destroyChildren();
    //   },2000)
    
  }

//   const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });
  
  document.addEventListener("keydown", ev => {
    if (ev.code == "Delete") {
      let index = circles.findIndex(c => c.id == selectedId);
      if (index != -1) {
        circles.splice(index, 1);
        setCircles(circles);
      }
      index = rectangles.findIndex(r => r.id == selectedId);
      if (index != -1) {
        rectangles.splice(index, 1);
        setRectangles(rectangles);
      }
      index = images.findIndex(r => r.id == selectedId);
      if (index != -1) {
        images.splice(index, 1);
        setImages(images);
      }
      forceUpdate();
    }
  });

  function getColorIndicesForCoord(x, y, width) {
    var red = y * (width * 4) + x * 4;
    return [red, red + 1, red + 2, red + 3];
  }

  const getFrameData = () => {

    const canvas = document.getElementById('testcanvas2');
    videoElement.currentTime = currentFrameTimeRef;
    // console.log('homepage',currentFrameTimeRef)
    const scaleX = stageDim[0] / videoElement.videoWidth;
    const scaleY = stageDim[1] / videoElement.videoHeight;
    var scale = Math.max(scaleX, scaleY)
    
    // scale the canvas accordingly
    canvas.width = videoElement.videoWidth*scale;
    canvas.height = videoElement.videoHeight*scale;
    videoElement.width = videoElement.videoWidth*scale;
    videoElement.height = videoElement.videoHeight*scale;
    // draw the video at that frame
    canvas.getContext('2d')
    .drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    // convert it to a usable data URL
    // const imageData = canvas.getContext('2d').getImageData(x,y,width, height);
    const imageData = canvas.getContext('2d').getImageData(0,0,canvas.width, canvas.height);
    return imageData;


    }

    const freeze = () => {

        let reverseFreezeFlag = document.getElementById('reverse_freeze').checked


        // const pointsAry = JSON.parse(localStorage.getItem('line-points'));
        const scaleX = stageDim[0] / videoElement.videoWidth;
        const scaleY = stageDim[1] / videoElement.videoHeight;
        var scale = Math.max(scaleX, scaleY)

        // const x = stageEl.current.find('.rectangle')[0].attrs.x;
        // const y = stageEl.current.find('.rectangle')[0].attrs.y;
        // const height = stageEl.current.find('.rectangle')[0].attrs.height;
        // const width = stageEl.current.find('.rectangle')[0].attrs.width;

        var canvas = document.getElementById('testcanvas');
        var ctx = canvas.getContext('2d');
        const frameData = getFrameData();
        canvas.width = videoElement.videoWidth*scale;
        canvas.height = videoElement.videoHeight*scale;
        var myImageData = ctx.createImageData(frameData);

        const ctx2 = layer2El.current.getContext();
        const lineImageData = ctx2.getImageData(0, 0, frameData.width, frameData.height);
        
        // console.log(frameData.width,frameData.height)
        // console.log(myImageData.width,myImageData.height)
        // console.log(lineImageData.width,lineImageData.height)

        for(var xcod = 1;xcod <= frameData.width;xcod++){
            for(var ycod = 1;ycod <= frameData.height;ycod++){
                var colorIndices = getColorIndicesForCoord(xcod, ycod, frameData.width);
                var redIndex = colorIndices[0];
                var greenIndex = colorIndices[1];
                var blueIndex = colorIndices[2];
                var alphaIndex = colorIndices[3];

                // console.log(lineImageData.data[redIndex])
                if(reverseFreezeFlag){
                  if(lineImageData.data[redIndex] != 255){
                    // console.log("changing")
                    myImageData.data[redIndex] = frameData.data[redIndex];
                    myImageData.data[greenIndex] = frameData.data[greenIndex];
                    myImageData.data[blueIndex] = frameData.data[blueIndex];
                    myImageData.data[alphaIndex] = frameData.data[alphaIndex];
                }
                }else{
                  if(lineImageData.data[redIndex] == 255){
                    // console.log("changing")
                    myImageData.data[redIndex] = frameData.data[redIndex];
                    myImageData.data[greenIndex] = frameData.data[greenIndex];
                    myImageData.data[blueIndex] = frameData.data[blueIndex];
                    myImageData.data[alphaIndex] = frameData.data[alphaIndex];
                }
                }
                

                
            }
        }

        ctx.putImageData(myImageData,0,0);

        // var image = new Konva.Image({
        //     image: canvas,
        //     draggable: true
        // });
        // layerEl.current.add(image);
        const id = uuidv1();
        images.push({
          content: canvas,
          srcType: 'canvas',
          id,
          draggable_: false,
          animation: {}
        });
        setImages(images);
        shapes.push(id);
        setShapes(shapes);
        forceUpdate();
        layer2El.current.destroyChildren();
        layer2El.current.hide()
        // stageEl.current.find('.rectangle')[0].destroy()
        // const id = uuidv1();
        // images.push({
        //   content: image,
        //   id,
        // });
        // setImages(images);
        // fileUploadEl.current.value = null;
        // shapes.push(id);
        // setShapes(shapes);
        // forceUpdate();
    }

    const onChangeCallbackTimeline = (selectedInterval) => {
        setSelectedInterval(selectedInterval);
        var startsec = 0;
        var stopsec = 0;
        if(selectedInterval[0].getHours() > 0){
          startsec = selectedInterval[0].getHours()*60 + selectedInterval[0].getMinutes();
        }else{
          startsec = selectedInterval[0].getMinutes();
        }

        if(selectedInterval[1].getHours() > 0){
          stopsec = selectedInterval[1].getHours()*60 + selectedInterval[1].getMinutes();
        }else{
          stopsec = selectedInterval[1].getMinutes();
        }
        setStartTimeSec(startsec)
        setStopTimeSec(stopsec)
        // console.log(selectedInterval[1].getHours(), startsec,stopsec)
        // console.log(videoElement.duration)
    }

    const errorHandlerTimeline = () => {

    }
    // setSelectedInterval([selectedStart,selectedEnd])

    // const startTime = getTodayAtSpecificHour(7)
    // const endTime = endOfToday()
    const ariaLabel = { 'aria-label': 'description' };

    const startTimeInputOnchange = (e) => {
      setStartTimeSec(e.target.value);
      const MINUTES = Math.floor(e.target.value)
      var m = MINUTES % 60;
      var h = (MINUTES-m)/60;
      selectedEnd = getTodayAtSpecificHour(h,m,0)
      setSelectedInterval([selectedEnd,selectedInterval[1]])
    }

    const stopTimeInputOnchange = (e) => {
      setStopTimeSec(e.target.value);
      const MINUTES = Math.floor(e.target.value)
      var m = MINUTES % 60;
      var h = (MINUTES-m)/60;
      selectedEnd = getTodayAtSpecificHour(h,m,0)
      setSelectedInterval([selectedInterval[0],selectedEnd])
    }

    useEffect(() => {
      setCurrentFrameTime(startTimeSec)
      setCurrentFrameTimeRef(startTimeSec)
    },[startTimeSec])

    useEffect(() => {
      setCurrentFrameTime(stopTimeSec)
      setCurrentFrameTimeRef(stopTimeSec)
    },[stopTimeSec])

    const handleSelectChange = (e) => {
      setAddType(e.target.value)
      if(e.target.value == 'rect'){
        addRectangle()
      }
      if(e.target.value == 'circle'){
        addCircle()
      }
    }

    const [addItemValue, setAddItemValue] = useState('');
    const [addButtonItemValue, setAddButtonItemValue] = useState('buttons/button1.png');
    

    function toDataURL(src, callback, outputFormat) {
      let image = new Image();
      image.crossOrigin = 'Anonymous';
      image.onload = function () {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      image.src = src;
      if (image.complete || image.complete === undefined) {
        image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        image.src = src;
      }
    }
    

    const handleAddItem = (e) => {
      setAddItemValue(e.target.name)
      // console.log(e.target)
      toDataURL(require('../assets/' + e.target.name),
      function (dataUrl) {
          // console.log('RESULT:', dataUrl)
          const id = uuidv1();
          images.push({
            content: dataUrl,
            id,
            draggable_:true,
          });
          setImages(images);
          shapes.push(id);
          setShapes(shapes);
          forceUpdate();
        }
      )
      
    }

    const handleAddButton = (e) => {
      setAddButtonItemValue(e.target.name)
    }



    var iconPack = [];
    for (var i = 1; i < 32; i++) {
      iconPack.push( <Button style={{display:'block'}} variant="outlined" onClick={handleAddItem} name={`shopping_icons/shopping${i}.png`}><img name={`shopping_icons/shopping${i}.png`} src={require(`../assets/shopping_icons/shopping${i}.png`)} width={'100px'} height={'100px'} style={{objectFit:'contain'}}/> </Button>);
    }

    var buttonPack = [];
    for (var i = 1; i <9; i++) {
      buttonPack.push( <Button style={{display:'block'}} variant="outlined" onClick={handleAddItem} name={`buttons/button${i}.png`}><img name={`buttons/button${i}.png`} src={require(`../assets/buttons/button${i}.png`)} width={'100px'} height={'100px'} style={{objectFit:'contain'}}/> </Button>);
    }

    var calloutPack = [];
    for (var i = 1; i <7; i++) {
      calloutPack.push( <Button style={{display:'block'}} variant="outlined" onClick={handleAddItem} name={`callout/callout${i}.png`}><img name={`callout/callout${i}.png`} src={require(`../assets/callout/callout${i}.png`)} width={'100px'} height={'100px'} style={{objectFit:'contain'}}/> </Button>);
    }

    var buttonPack2 = [];
    for (var i = 1; i <9; i++) {
      buttonPack2.push( <Button style={{display:'block'}} variant="outlined" onClick={handleAddButton} name={`buttons/button${i}.png`}><img name={`buttons/button${i}.png`} src={require(`../assets/buttons/button${i}.png`)} width={'100px'} height={'100px'} style={{objectFit:'contain'}}/> </Button>);
    }

    var calloutPack2 = [];
    for (var i = 1; i <7; i++) {
      calloutPack2.push( <Button style={{display:'block'}} variant="outlined" onClick={handleAddButton} name={`callout/callout${i}.png`}><img name={`callout/callout${i}.png`} src={require(`../assets/callout/callout${i}.png`)} width={'100px'} height={'100px'} style={{objectFit:'contain'}}/> </Button>);
    }

    var socialPack = [];
    for (var i = 1; i <11; i++) {
      socialPack.push( <Button style={{display:'block'}} variant="outlined" onClick={handleAddItem} name={`social/social${i}.png`}><img name={`social/social${i}.png`} src={require(`../assets/social/social${i}.png`)} width={'100px'} height={'100px'} style={{objectFit:'contain'}}/> </Button>);
    }

    const reset = () => {
      setSelectedTool('reset')
      layer2El.current.destroyChildren();
      layerEl.current.destroyChildren();
      // layer0El.current.destroyChildren();
    }

    const fonts = [
      {
        value: 'Times New Roman',
        label: 'Times New Roman',
      },
      {
        value: 'Georgia',
        label: 'Georgia',
      },
      {
        value: 'Arial',
        label: 'Arial',
      },
      {
        value: 'Verdana',
        label: 'Verdana',
      },
      {
        value: 'Courier New',
        label: 'Courier New',
      },
      {
        value: 'Lucida Console',
        label: 'Lucida Console',
      },
      {
        value: 'Lucida Handwriting',
        label: 'Lucida Handwriting',
      },
      {
        value: 'Copperplate',
        label: 'Copperplate',
      },
    ];

    const textdecoration = [
      {
        value: 'overline',
        label: 'overline',
      },
      {
        value: 'line-through',
        label: 'line-through',
      },
      {
        value: 'underline',
        label: 'underline',
      },
      {
        value: 'overline underline',
        label: 'overline underline',
      },
      {
        value: 'none',
        label: 'none',
      },
    ];

    const fontstyle = [
      {
        value: 'normal',
        label: 'normal',
      },
      {
        value: 'italic',
        label: 'italic',
      },
    ];

    var fontweight = [];
    for(var i=100;i<1000;i = i + 100){
      fontweight.push({
        value: `${i}`,
        label: `${i}`,
      })
    }

    const [fontFamily, setFontFamily] = useState('Copperplate');
    const [inputText, setInputText] = useState('Text');
    const [bgColor,setBgColor] = useState({r:0,g:0,b:0,a:0})
    const handleFontChange = (e)=>{
      setFontFamily(e.target.value)
      var tmp = Object.assign({},textStyle)
      tmp.fontFamily = e.target.value;
      setTextStyle(tmp);
    }
    const handleTextChange = (e) => {
      setInputText(e.target.value)
    }
    const handleStyleChange = (e) => {
      if(e.target.name == 'fontSize' || e.target.name == 'letterSpacing'){
        var r = /\d+/;
        setTextStyle({
          ...textStyle,
          [e.target.name] : e.target.value.match(r) + 'px'
        })
      }else{
        setTextStyle({
          ...textStyle,
          [e.target.name] : e.target.value
        })
      }
    }

    const handleColorChange = (color) => {
      setTextStyle({
        ...textStyle,
        color : color.hex
      })
    }

    const handleBackgroundColorChange = (color) => {
      setTextStyle({
        ...textStyle,
        backgroundColor : `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
      })
      setBgColor(color.rgb)
    }

    const addToCanvas = (elm) => {
      layerEl.current.add(elm)
      // console.log(elm)
    }

    const addTextElement = () => {

      if(animradio === "0"){
        setanimFlag2(false)
      }else{
        setanimFlag2(true)
      }
      console.log(animFlag2)
     
      html2canvas(document.querySelector('#text-preview'), {
        backgroundColor: 'rgba(0,0,0,0)',
      }).then((canvas) => {
        // show it inside Konva.Image
        const id = uuidv1();
        images.push({
          content: canvas,
          srcType: 'canvas',
          draggable_:true,
          id,
          animation: {type:parseInt(animradio)}
        });
        setImages(images);
        shapes.push(id);
        setShapes(shapes);
        forceUpdate();
      });
    }

    const addButtonElement = () => {
     
      html2canvas(document.querySelector('#button_container'), {
        backgroundColor: 'rgba(0,0,0,0)',
      }).then((canvas) => {
        // show it inside Konva.Image
        const id = uuidv1();
        images.push({
          content: canvas,
          srcType: 'canvas',
          draggable_:true,
          id,
          animation: {}
        });
        setImages(images);
        shapes.push(id);
        setShapes(shapes);
        forceUpdate();
      });
    }

    // useEffect(()=>{
    //   console.log(stageDim)},[stageDim])

    const addAudio = ()=>{
      setSelectedTool("audio");
    }

    

    const handleFormatChange = (e) => {
      setRenderFormat(e.target.value)
    }

    const handleCloseTool = ()=>{
      setOpenTool(false)
    }

    useEffect(()=>{
      if(selectedTool == 'add' || selectedTool == 'audio' || selectedTool == 'text' || selectedTool == 'render')
      setOpenTool(true)
    },[selectedTool])

    const handlePlayBack = (e,name)=>{
      // setPlayBack(!playback)
      // console.log(e.target.data-testid)
      if(name == "play"){
        setPlayBack("play")
      }else if(name == "pause"){
        setPlayBack("pause")
      }else if(name == "stop"){
        setPlayBack("stop")
      }
    }



    function filterdata(){
      const data = `<svg id="svgfilters" aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter id="teal-white" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.03 1"/>
            <feFuncG type="table" tableValues="0.57 1"/>
            <feFuncB type="table" tableValues="0.49 1"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="teal-lightgreen" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.03 0.8"/>
            <feFuncG type="table" tableValues="0.57 1"/>
            <feFuncB type="table" tableValues="0.49 0.53"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="sepia" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.26 0.95"/>
            <feFuncG type="table" tableValues="0.19 0.78"/>
            <feFuncB type="table" tableValues="0.11 0.59"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="purple-sepia" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.43 0.97"/>
            <feFuncG type="table" tableValues="0.06 0.88"/>
            <feFuncB type="table" tableValues="0.37 0.79"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="cherry-icecream" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.84 1"/>
            <feFuncG type="table" tableValues="0.05 0.94"/>
            <feFuncB type="table" tableValues="0.37 0.61"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="blackCurrant-and-mint" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.75 0.53"/>
            <feFuncG type="table" tableValues="0.25 0.97"/>
            <feFuncB type="table" tableValues="0.64 0.77"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="sea" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.02 0.13 0.8"/>
            <feFuncG type="table" tableValues="0.02 0.47 0.97"/>
            <feFuncB type="table" tableValues="0.26 0.52 0.48"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="warm-sea" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.29 0.01 0.97"/>
            <feFuncG type="table" tableValues="0.12 0.52 0.94"/>
            <feFuncB type="table" tableValues="0.37 0.59 0.47"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="spring-grass" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0 0.38 0.92"/>
            <feFuncG type="table" tableValues="0.5 0.8 1"/>
            <feFuncB type="table" tableValues="0.5 0.56 0.74"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="red-sunset-with-purple" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.52 0.86 0.97"/>
            <feFuncG type="table" tableValues="0 0.08 0.81"/>
            <feFuncB type="table" tableValues="0.51 0.24 0.05"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="red-sunset" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.27 0.86 0.97"/>
            <feFuncG type="table" tableValues="0.01 0.08 0.81"/>
            <feFuncB type="table" tableValues="0.02 0.24 0.05"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="gold-sunset" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.27 0.86 1"/>
            <feFuncG type="table" tableValues="0.01 0.31 0.95"/>
            <feFuncB type="table" tableValues="0.02 0.02 0.02"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="dark-crimson-sepia" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.01 0.52 0.97"/>
            <feFuncG type="table" tableValues="0 0.05 0.81"/>
            <feFuncB type="table" tableValues="0.02 0.29 0.61"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="dark-blue-sepia" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.29 0.15 0.97"/>
            <feFuncG type="table" tableValues="0.04 0.39 0.93"/>
            <feFuncB type="table" tableValues="0.32 0.52 0.73"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="dark-green-sepia" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.25 0.39 0.96"/>
            <feFuncG type="table" tableValues="0.16 0.52 0.97"/>
            <feFuncB type="table" tableValues="0.06 0.39 0.78"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="x-rays" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.98 0.3 0.25"/>
            <feFuncG type="table" tableValues="1 0.44 0.24"/>
            <feFuncB type="table" tableValues="0.91 0.62 0.39"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="warm-x-rays" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.98 0.75 0.51"/>
            <feFuncG type="table" tableValues="1 0.45 0.11"/>
            <feFuncB type="table" tableValues="0.91 0.39 0.29"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="golden-x-rays" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.98 1 0.94"/>
            <feFuncG type="table" tableValues="1 0.98 0.44"/>
            <feFuncB type="table" tableValues="0.91 0.43 0.02"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="purple-warm" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.52 0.97 1"/>
            <feFuncG type="table" tableValues="0 0.62 1"/>
            <feFuncB type="table" tableValues="0.51 0.39 0.89"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="green-pink-acid" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="1 0.98 0.1"/>
            <feFuncG type="table" tableValues="0.17 1 0.82"/>
            <feFuncB type="table" tableValues="0.7 0.84 0.67"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="yellow-blue-acid" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feColorMatrix type="matrix" values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0" in="SourceGraphic" result="colormatrix"/>
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.01 0.97 0.89"/>
            <feFuncG type="table" tableValues="0.38 1 1"/>
            <feFuncB type="table" tableValues="1 0.89 0.01"/>
            <feFuncA type="table" tableValues="0 1"/>
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend"/>
        </filter>
        <filter id="noise" x="0%" y="0%" width="100%" height="100%">
          <feTurbulence baseFrequency="0.01 0.4" result="NOISE" numOctaves="2" />
          <feDisplacementMap in="SourceGraphic" in2="NOISE" scale="20" xChannelSelector="R" yChannelSelector="R"></feDisplacementMap>
        </filter>
        <filter id="squiggly-0">
          <feTurbulence id="turbulence1" baseFrequency="0.02" numOctaves="3" result="noise" seed="0" />
          <feDisplacementMap id="displacement" in="SourceGraphic" in2="noise" scale="6" />
        </filter>
        <filter id="squiggly-1">
          <feTurbulence id="turbulence2" baseFrequency="0.02" numOctaves="3" result="noise" seed="1" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
        </filter>
        <filter id="squiggly-2">
          <feTurbulence id="turbulence3" baseFrequency="0.02" numOctaves="3" result="noise" seed="2" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
        </filter>
        <filter id="squiggly-3">
          <feTurbulence id="turbulence4" baseFrequency="0.02" numOctaves="3" result="noise" seed="3" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
        </filter>
        <filter id="squiggly-4">
          <feTurbulence id="turbulence5" baseFrequency="0.02" numOctaves="3" result="noise" seed="4" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
        </filter>
        <filter id="posterize">
          <feComponentTransfer>
            <feFuncR type="discrete" tableValues="0 .5 1" />
          </feComponentTransfer>
        </filter>
        <filter id="dancing" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="linearRGB">
          <feMorphology operator="dilate" radius="4 4" in="SourceAlpha" result="morphology"/>
          <feFlood flood-color="#30597E" flood-opacity="1" result="flood"/>
          <feComposite in="flood" in2="morphology" operator="in" result="composite"/>
          <feComposite in="composite" in2="SourceAlpha" operator="out" result="composite1"/>
          <feTurbulence type="fractalNoise" baseFrequency="0.01 0.02" numOctaves="1" seed="0" stitchTiles="stitch" result="turbulence"/>
          <feDisplacementMap in="composite1" in2="turbulence" scale="17" xChannelSelector="A" yChannelSelector="A" result="displacementMap"/>
          <feMerge result="merge">
            <feMergeNode in="SourceGraphic"/>
            <feMergeNode in="displacementMap"/>
            </feMerge>
        </filter>
        <filter id="drops" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feTurbulence type="turbulence" baseFrequency="0.05 0.05" numOctaves="1" seed="3" stitchTiles="stitch" result="turbulence"/>
          <feComposite in="turbulence" in2="SourceGraphic" operator="in" result="composite"/>
          <feColorMatrix type="matrix" values="1 0 0 0 0
            0 1 0 0 0
            0 0 1 0 0
            0 0 0 25 -2" in="composite" result="colormatrix"/>
          <feComposite in="SourceGraphic" in2="colormatrix" operator="in" result="composite1"/>
          <feGaussianBlur stdDeviation="3 3" in="composite1" result="blur"/>
          <feSpecularLighting surfaceScale="2" specularConstant="1" specularExponent="20" lighting-color="#fffffd" in="blur" result="specularLighting">
            <feDistantLight azimuth="-90" elevation="150"/>
          </feSpecularLighting>
          <feSpecularLighting surfaceScale="2" specularConstant="1" specularExponent="20" lighting-color="#cae1fe" in="blur" result="specularLighting1">
            <feDistantLight azimuth="90" elevation="150"/>
          </feSpecularLighting>
          <feSpecularLighting surfaceScale="7" specularConstant="1" specularExponent="35" lighting-color="#fcfeff" in="blur" result="specularLighting2">
            <fePointLight x="150" y="50" z="300"/>
          </feSpecularLighting>
          <feComposite in="specularLighting" in2="composite1" operator="in" result="composite2"/>
          <feComposite in="specularLighting2" in2="composite1" operator="in" result="composite3"/>
          <feComposite in="specularLighting1" in2="composite1" operator="in" result="composite4"/>
          <feBlend mode="multiply" in="composite4" in2="SourceGraphic" result="blend"/>
          <feBlend in="composite2" in2="blend" result="blend1"/>
          <feBlend in="composite3" in2="blend1" result="blend2"/>
        </filter>
        <filter id="grain">
          <feTurbulence baseFrequency="0.60,0.90" result="colorNoise" />
          <feColorMatrix in="colorNoise" type="matrix" values=".33 .33 .33 0 0 .33 .33 .33 0 0 .33 .33 .33 0 0 0 0 0 1 0"/>
          <feComposite operator="in" in2="SourceGraphic" result="monoNoise"/>
          <feBlend in="SourceGraphic" in2="monoNoise" mode="multiply" />
        </filter>
        <filter id="fluffy" x="0%" y="0%" width="100%" height="100%">
           <feTurbulence type="fractalNoise" baseFrequency="0.04" result="fluffyNoise" numOctaves="5" />
          <feColorMatrix in="fluffyNoise" type="matrix" values=".33 .33 .33 0 0 .33 .33 .33 0 0 .33 .33 .33 0 0 0 0 0 1 0"/>
          <feComposite operator="in" in2="SourceGraphic" result="monoFluffyNoise"/>
          <feBlend in="SourceGraphic" in2="monoFluffyNoise" mode="screen" />
        </filter>
      </defs>
    </svg>`

    return (
      <div dangerouslySetInnerHTML={{__html: data}}>

      </div>)
    }

  const handleFullscreen = (e) =>{
    setStageFullscreen(!stageFullscreen)
  }

  useEffect(()=>{
    if(stageFullscreen){
      setStageDim([window.innerWidth,window.innerHeight])
    }else{
      if(width <= 768){
        setStageDim([window.innerWidth* 0.9 ,window.innerWidth* 0.9 * 0.5])
      }else{
        setStageDim([window.innerWidth* (2/3) * 0.9 ,window.innerWidth* (2/3) * 0.9 * 0.5])
      }
    }
  },[stageFullscreen])

  const handleShareChange = (e) => {
    setSharevalue(e.target.value)
  }

  const downloadOutput = ()=>{
    downloadURI(downloadUrl,'out.'+renderFormat)
  }

  var fbAccessToken = null;

  window.fbAsyncInit = function() {
    window.FB.init({
      appId      : '475545834314659',
      cookie     : true,
      xfbml      : true,
      version    : 'v13.0'
    });
      
    window.FB.AppEvents.logPageView();   
      
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));

  const FBshare = () => {

  window.FB.getLoginStatus(function(response) {
    if(response.status != 'connected'){
      window.FB.login((response=>{
        fbAccessToken = response.authResponse.accessToken;
        window.FB.api(
          `/${response.authResponse.userID}/videos`,
          "POST",
          {
              "source": formData2,
              "access_token": response.authResponse.accessToken
          },
          function (response) {
            if (response && !response.error) {
              /* handle the result */
              // console.log(response)
            }
          }
      );
      }))
    }else{
      fbAccessToken = response.authResponse.accessToken;
      window.FB.api(
        `/${response.authResponse.userID}/videos`,
        "POST",
        {
            "source": formData2,
            "access_token": response.authResponse.accessToken
        },
        function (response) {
          if (response && !response.error) {
            /* handle the result */
            // console.log(response)
          }
        }
    );
    }
  });

  }

  function valuetext(value) {
    return `${value}°C`;
    setSliderVal(value)
  }

  // useEffect(()=>{
  //   console.log(fileItems)
  //   console.log(fileLenghts)
  // },[fileItems])

  // useEffect(()=>{
  //   console.log(fileItems)
  //   console.log(fileLenghts)
  // },[props.video_,audio])

  const onAudioChange = (e) => {
    setAudio(e.target.files?.item(0));
    const audioSource = document.getElementById("audiosource")
    const audioElement = document.getElementById("testaudio")
    // console.log(audioSource)
    audioSource.setAttribute('src',URL.createObjectURL(e.target.files?.item(0)))
    audioElement.removeChild(audioSource)
    audioElement.appendChild(audioSource)
    

    const onload = function() {
      const SECONDS = Math.floor(audioElement.duration)
      fileLen_[props.video_.name] = videoElement.duration
      fileLen_[e.target.files?.item(0).name] = SECONDS
      // console.log(fileLen_)
      setFileLenghts(fileLen_)
      setfileItems([props.video_,e.target.files?.item(0)])
    };
    audioElement.addEventListener("loadedmetadata", onload);
    return () => {
        audioElement.removeEventListener("loadedmetadata", onload);
    };
  }

  function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };


  function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

  useEffect(()=>{
    // console.log(tabvalue)
    if(tabvalue == 0){
      document.getElementById('video_track_div').style.display = 'block';
      document.getElementById('audio_track_div').style.display = 'none'
    }
    if(tabvalue == 1){
      document.getElementById('video_track_div').style.display = 'none';
      document.getElementById('audio_track_div').style.display = 'block'
    }
  },[tabvalue])

  const setAudioTime = (time) => {
    setStartAudioTimeSec(time[0])
    setStopAudioTimeSec(time[1])
    // console.log(time)
  }

  const handleTrim = () => {
    var startPercent = parseInt((startTimeSec/videoElement.duration)*70.5)
    // console.log(startTimeSec,videoElement.duration, parseInt((startTimeSec/videoElement.duration)*69),parseInt(((stopTimeSec - startTimeSec)/videoElement.duration)*85))
    var widthPercent = parseInt(((stopTimeSec - startTimeSec)/videoElement.duration)*70.5)
    setCutPoints([...cutPoints,[widthPercent,startPercent + 15.5]])
    setCutPointSec([...cutPointSec,[startTimeSec,stopTimeSec]])
    // parseInt(currentFrameTimeRef,(currentFrameTimeRef/videoElement.duration)*70.5)
    // const container = videoTimelineEl.current;
    // const parser = new DOMParser();
    // var elem = <div style={{'backgroundColor':'rgba(0,0,255,0.3)','position':'absolute','width':'20px','height':'90%','left':'30%'}}></div>
    // ReactDOM.render(elem,container)

    // var marker = parser.parseFromString(`<div style="background-color:rgba(0,0,255,0.3);position:absolute;width:20px;height:90%;left:30%;"></div>`,'text/xml').documentElement
    // // console.log(document.getElementById('video_timeline'))
    // marker.style.width = '20px'
    // marker.style.height = '68px'
    // document.getElementById('video_timeline').appendChild(marker)
  }

  const handleTrimUndo = () => {
    // var select = document.getElementById('video_timeline');
    // select.removeChild(select.lastChild);
    setCutPoints((cutPoints) => cutPoints.filter((_, i) => i !== cutPoints.length - 1))
    setCutPointSec((cutPointSec) => cutPointSec.filter((_, i) => i !== cutPointSec.length - 1))
  }

  const setPlayState = (state)=>{
    setPlayBack(state)
  }

  useEffect(()=>{
    if(selectedTool == "button"){
      var offset = [0,0];
      var divOverlay = document.getElementById ("text_container");
      var isDown = false;

      divOverlay.addEventListener('mousedown', function(e) {
      isDown = true;
      offset = [
          divOverlay.offsetLeft - e.clientX,
          divOverlay.offsetTop - e.clientY
       ];
      }, true);

      document.addEventListener('mouseup', function() {
         isDown = false;
      }, true);

      document.addEventListener('mousemove', function(e) {
          e.preventDefault();
          if (isDown) {
              divOverlay.style.left = (e.clientX + offset[0]) + 'px';
              divOverlay.style.top  = (e.clientY + offset[1]) + 'px';
         }
      }, true);
    }
  },[selectedTool])

  const receiveItems = (items) => {
    // console.log(items)
    timelineItems = items
  }

  // CanvasCapture.init(
  //   layerEl.current.getCanvas(),
  //   { showRecDot: true }, // Options are optional, more info below.
  // );

  // function renderAnim(){
  //   // requestAnimationFrame(renderAnim);
  //   // // rendering stuff ...
  //   // capturer.capture( layerEl.current.getCanvas() );
  //   requestAnimationFrame(renderAnim);

  //   // Render something...

  //   // It is recommended to use checkHotkeys() right after rendering
  //   // frame in render loop to ensure that PNG and JPEG
  //   // snapshots are triggered at the right time.
  //   // Otherwise, blank images may be generated due to the browser
  //   // clearing the render buffer before onKeyDown is triggered.
  //   // CanvasCapture.checkHotkeys();

  //   // You need to call recordFrame() only if you are recording
  //   // a video, gif, or frames.
  //   if (CanvasCapture.isRecording()) CanvasCapture.recordFrame();
  // }

  // var videoStream = layerEl.current.getCanvas().captureStream(30);
  // var mediaRecorder = new MediaRecorder(videoStream);

  // var chunks = [];
  // mediaRecorder.ondataavailable = function(e) {
  //   chunks.push(e.data);
  // };

  // mediaRecorder.onstop = function(e) {
  //   var blob = new Blob(chunks, { 'type' : 'video/mp4' }); // other types are available such as 'video/webm' for instance, see the doc for more info
  //    chunks = [];
  //    var videoURL = URL.createObjectURL(blob);
  //   //  video.src = videoURL;
  //   downloadUrl(videoURL)
  //  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const render2 = (e) =>{
    if(animFlag2){
      saveAnim(e)
      // render(e)
      console.log("saveAnim()")
    }else{
      render(layerEl.current)
      console.log("render()")
    }
  }

  

  function saveAnim(ee){
    var canvas = layerEl.current.getNativeCanvasElement()
    var layy = layerEl.current
    
    var videoStream = canvas.captureStream(30);
    var mediaRecorder = new MediaRecorder(videoStream);

    var chunks = [];
    mediaRecorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    var root = this;

    function stopevent(e){
      var blob = new Blob(chunks, { 'type' : 'video/mp4' }); // other types are available such as 'video/webm' for instance, see the doc for more info
      chunks = [];
      // animFile = new File([blob],"anim.mp4")
      setanimFile(new File([blob],"anim.mp4"))
      layy.destroyChildren();
      
      // var videoURL = URL.createObjectURL(blob);
      //  video.src = videoURL;
      // downloadURI(videoURL)
      render(layy);
    }

    mediaRecorder.addEventListener("stop", stopevent.bind(null));
    setAnimplayBack('play')
    mediaRecorder.start();
    
    // CanvasCapture.beginVideoRecord({ format: CanvasCapture.MP4 });
    // CanvasCapture.recordFrame();
    // renderAnim()
    // await sleep(2000);
    // mediaRecorder.stop();
    setTimeout(() => {
      // CanvasCapture.stopRecord();
      mediaRecorder.stop();
      // console.log(layerEl.current)
      
      }, 2000);
  }

  const handleAnimRadioChange = (event) => {
    setAnimradio(event.target.value);
    if(event.target.value === "0"){
      animFlag1 = false
    }else{
      animFlag1 = true
    }
  };


  return (
    <div className="home-page" style={{margin:'0 2rem'}}>
      <br/>
      <h1>Editor</h1>
      <br/>
      <ButtonGroup size={isMobile? 'small':'large'} className="d-flex flex-wrap">
        {/*<Button variant="secondary" onClick={addRectangle}>
          Rectangle
        </Button>
        <Button variant="secondary" onClick={addCircle}>
          Circle
        </Button>*/}
        <Button variant="secondary" onClick={drawLine}>
          Select
        </Button>
        <Button variant="secondary" onClick={saveAnim}>
          Save Anim
        </Button>
        <Button variant="secondary" onClick={eraseLine}>
          Erase
        </Button>
        <Button variant="secondary" onClick={drawText}>
          Text
        </Button>
        <Button variant="secondary" onClick={drawImage}>
          Image
        </Button>
        <Button variant="secondary" onClick={addShape}>
          Add
        </Button>
        <Button variant="secondary" onClick={addButton}>
          Button/Callout
        </Button>
        {/*<Button variant="secondary" onClick={addAudio}>
          Audio
        </Button>*/}
        <Button variant="secondary" onClick={handleClickOpen('paper')}>
            Filter
        </Button>
        <Button variant="secondary" onClick={undo}>
          Undo
        </Button>
        <Button variant="secondary" onClick={freeze}>
          Freeze
        </Button>
        {/*<Button variant={buttontype} onClick={loop}>
          Loop
        </Button>*/}
        <Button variant="secondary" onClick={renderClick}>
          Download
        </Button>
        <Button variant="secondary" onClick={reset}>
          Reset
        </Button>
      </ButtonGroup>
        <br/>

        <Grid container spacing={2}>
          <Grid item xs={isMobile?12:8}>
            {/* <Item>xs=8</Item> */}
            {/* Filter Selection Dialog */}

          <Dialog
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
          >
              <DialogTitle id="scroll-dialog-title">Filters</DialogTitle>
              <DialogContent dividers={scroll === 'paper'}>
              <FormControl>
                  {/* <FormLabel id="demo-radio-buttons-group-label">Select</FormLabel> */}
                  <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="none"
                      name="radio-buttons-group"
                      value={filterValue}
                      onChange={handleChangeFilter}
                  >
                      <FormControlLabel value="none" control={<Radio/>} label={<img alt="none" style={{margin:'0.5rem'}} src={filter_none} width={'200px'} height={'100px'} />} />
                        <FormControlLabel value="invert()" control={<Radio/>} label={<img alt="invert" style={{margin:'0.5rem'}} src={filter_inv} width={'200px'} height={'100px'} />} />
                        <FormControlLabel value="blur(5px)" control={<Radio/>} label={<img alt="blur" style={{margin:'0.5rem'}} src={filter_blur} width={'200px'} height={'100px'} />} />
                        <FormControlLabel value="contrast(200%)" control={<Radio />} label={<img alt="contrast" style={{margin:'0.5rem'}} src={filter_contrast} width={'200px'} height={'100px'} />} />
                        <FormControlLabel value="grayscale(100%)" control={<Radio />} label={<img alt="grayscale" style={{margin:'0.5rem'}} src={filter_grey} width={'200px'} height={'100px'} />} />
                        <FormControlLabel value="hue-rotate(90deg)" control={<Radio />} label={<img alt="hue" style={{margin:'0.5rem'}} src={filter_hue} width={'200px'} height={'100px'} />} />
                        <FormControlLabel value="hue-rotate(45deg)" control={<Radio />} label={<img alt="hue" style={{margin:'0.5rem'}} src={filter_hue2} width={'200px'} height={'100px'} />} />
                        <FormControlLabel value="hue-rotate(180deg)" control={<Radio />} label={<img alt="hue" style={{margin:'0.5rem'}} src={filter_hue3} width={'200px'} height={'100px'} />} />
                        <FormControlLabel value="hue-rotate(270deg)" control={<Radio />} label={<img alt="hue" style={{margin:'0.5rem'}} src={filter_hue4} width={'200px'} height={'100px'} />} />
                        {/* <FormControlLabel value="url(#teal-white)" control={<Radio />} label={<img alt="hue" style={{margin:'0.5rem'}} src={filter_hue} width={'200px'} height={'100px'} />} /> */}
                  </RadioGroup>
              </FormControl>
              </DialogContent>
              <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
          </Dialog>
            {/* <IconButton onClick={loop} color={loop_state?'primary':'none'}>
              <LoopIcon />
            </IconButton> */}

            {/* <h5>Current Frame Time: {currentFrameTime}</h5> */}
            <div style={{position:'relative',margin:'0 auto'}}>
            {/* <canvas id="animcanvas" width={stageDim[0]} height={stageDim[1]}></canvas> */}
            <Stage
              width={stageDim[0]}
              height={stageDim[1]}
              ref={stageEl}
              onContextMenu={e=>{
                selectShape(null);
                e.evt.preventDefault();
              }}
              onMouseDown={e => {
                // deselect when clicked on empty area
                const clickedOnEmpty = e.target === e.target.getStage();
                if (clickedOnEmpty) {
                  selectShape(null);
                }
              }}
            >
                <Layer ref={layer0El}>
                  <Video src={props.video_} stageDimHandler={setStageDimension} playHandler={setPlayState} mute={muteVideo} handler={setCurrentTime} start={startTimeSec} stop={stopTimeSec} loop_={loop_state} currentTime_={currentFrameTime} play={playback} stageDim_={stageDim}/>
              </Layer>
              
              <Layer ref={layerEl}>
                {rectangles.map((rect, i) => {
                  return (
                    <Rectangle
                      key={i}
                      shapeProps={rect}
                      isSelected={rect.id === selectedId}
                      onSelect={() => {
                        selectShape(rect.id);
                      }}
                      onChange={newAttrs => {
                        const rects = rectangles.slice();
                        rects[i] = newAttrs;
                        setRectangles(rects);
                      }}
                    />
                  );
                })}
                {circles.map((circle, i) => {
                  return (
                    <Circle
                      key={i}
                      shapeProps={circle}
                      isSelected={circle.id === selectedId}
                      onSelect={() => {
                        selectShape(circle.id);
                      }}
                      onChange={newAttrs => {
                        const circs = circles.slice();
                        circs[i] = newAttrs;
                        setCircles(circs);
                      }}
                    />
                  );
                })}
                {images.map((image, i) => {
                  return (
                    <CanvasImage
                      key={i}
                      imageUrl={image.content}
                      srcType={image.srcType}
                      animation={image.animation}
                      play={animplayback}
                      draggable_={image.draggable_}
                      isSelected={image.id === selectedId}
                      onSelect={() => {
                        selectShape(image.id);
                      }}
                      onChange={newAttrs => {
                        const imgs = images.slice();
                        imgs[i] = newAttrs;
                      }}
                    />
                  );
                })}
              </Layer>

              <Layer ref={layer2El}></Layer>
              
            </Stage>
            <div style={{backgroundColor:'rgba(255,255,255,0.3)',width:"100%"}} className="d-flex align-items-center">
            
              <div style={{display:'inline-block'}}>
                <IconButton onClick={(e) => handlePlayBack(e, 'play')}>
                  <PlayArrowIcon/>
                </IconButton>
                <IconButton onClick={(e) => handlePlayBack(e, 'pause')}>
                  <PauseIcon/>
                </IconButton>
                <IconButton onClick={(e) => handlePlayBack(e, 'stop')}>
                  <StopIcon/>
                </IconButton>
                <IconButton onClick={(e) => setMuteVideo(!muteVideo)}>
                  {muteVideo && <VolumeOffIcon/>}
                  {!muteVideo && <VolumeUpIcon/>}
                </IconButton>
                <IconButton onClick={loop}>
                  {!loop_state && <LoopIcon/>}
                  {loop_state && <LoopIcon color="primary"/>}
                </IconButton>
                {/* <IconButton onClick={handleFullscreen}>
                  {!stageFullscreen && <FullscreenIcon/>}
                  {stageFullscreen && <FullscreenExitIcon/>}
                </IconButton> */}
                <span>{timeStamp}</span>
              </div>
              {/* <div style={{display:'inline-block'}}> */}
              <Slider 
                ref={sliderEl} 
                sx={{ margin: "2px 6px", width: "50%"}}   
                aria-label="Timeline" 
                value={sliderVal} 
                onChange={handleSliderChange} 
                min={0} 
                max={parseInt(stopTimeSec - startTimeSec)} 
                step={1}/>
              {/* <Slider
                aria-label="Temperature"
                // defaultValue={30}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                value={sliderVal}
                step={1}
                marks
                min={0}
                max={stopTimeSec - startTimeSec}
              /> */}
            {/* </div> */}

            </div>
            
            </div>
            {/* Video Trim Timeline */}
            <Box sx={{ width: '100%' }}>
            <ButtonGroup>
              <Button size={isMobile? 'small':'large'} variant={tabvalue == 0? 'primary':'secondary'} onClick={(e)=>{setTabvalue(0)}}>Video</Button><Button variant={tabvalue == 1? 'primary':'secondary'} onClick={(e)=>{setTabvalue(1)}}>Audio</Button>
            </ButtonGroup>
            <div id="video_track_div">

              <Box
                sx={{
                  // border: '1px solid grey',
                  '& > :not(style)': { m: 1 },
                  padding: '5px',
                }}>
              <span>{props.video_.name}</span> <br/> 
              <Input name="startTimeInput" value={startTimeSec} onChange={startTimeInputOnchange}  label="Start Time" inputProps={ariaLabel} />
              <Input name="stopTimeInput" value={stopTimeSec} onChange={stopTimeInputOnchange}  label="End Time" inputProps={ariaLabel} />
              {/* <IconButton
                edge="start"
                color="inherit"
                onClick={handleTrim}
                aria-label="close"
              >
                <ContentCutIcon/>
              </IconButton> */}
              {/* <IconButton
                edge="start"
                color="inherit"
                onClick={handleTrimUndo}
                aria-label="close"
              >
                <UndoIcon/>
              </IconButton> */}
              {/*<Input name="startTimeInput" value={startTimeSec} onChange={startTimeInputOnchange}  label="Start Time" inputProps={ariaLabel} />*/}
            <div ref={videoTimelineEl} id="video_timeline" style={{'display':'block','position':'relative'}}>
            {/*<div style={{'backgroundColor':'rgba(0,0,255,0.3)','position':'absolute','width':'20px','height':'90%','left':'15%'}}></div>*/}
            {/*{frames && <div style={{'position':'absolute','width':'70%','height':'80%','zIndex':'1','margin':'0 15%'}}>
              <img src={frames[0]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[1]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[0]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[1]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[0]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[1]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
            </div>}*/}
            {/* <div style={{'position':'absolute','width':'auto','height':'90%','left':`${parseInt((currentFrameTimeRef/videoElement.duration)*70.5) +14}%`,'zIndex':'6'}}>
              <div className="cone"></div>
              <div style={{'backgroundColor':'black','width':'2px','height':'65%','margin':'0 auto'}}></div>
              <div className="cone2"></div>
            </div> */}
            {/*cutPoints && cutPoints.map(function(item){
                return <div style={{'backgroundColor':'rgba(255,0,0,0.3)','position':'absolute','width':`${item[0]}%`,'height':'90%','left':`${item[1]}%`,'zIndex':'2'}}></div>;
              })*/}
            {/* <TimeRange
                error={false}  
                ticksNumber={36}
                step={60000}  
                selectedInterval={selectedInterval}  
                timelineInterval={timelineInterval}  
                onUpdateCallback={errorHandlerTimeline}  
                onChangeCallback={onChangeCallbackTimeline}
              /> */}
              
              </div>
              
              {/*<Input name="stopTimeInput" value={stopTimeSec} onChange={stopTimeInputOnchange}  label="End Time" inputProps={ariaLabel} />*/}
              </Box>
            </div>
            <div id="audio_track_div">
              {audio && 
                <Box
                sx={{
                  // border: '1px solid grey',
                  padding: '5px',
                }}>
                <span>{audio.name}</span> <IconButton onClick={()=>{setAudio(null)}}><CloseIcon/></IconButton>
                <AudioTrack src={document.getElementById("testaudio")} setTimeRange={setAudioTime} videoStopTimeSec={stopTimeSec - startTimeSec} loop={loop_state} currentTime={ currentFrameTime - startTimeSec} play={playback}/>
                </Box>}
                {!audio && 
                  <React.Fragment>
                  <h5 style={{margin:'1rem'}}>Add Audio</h5>
                  <input style={{color:'transparent'}} type="file" accept=".mp3" onChange={onAudioChange} />
                  <br/>
                  {audio && 
                  <audio style={{margin:'1rem'}} controls>
                  <source src={URL.createObjectURL(audio)}/>
                </audio>}
                </React.Fragment>
                }
            </div>
          </Box>
          

              

          </Grid>
          {!isMobile &&
          <Grid item xs={4}>
            {selectedTool == 'line' && 
              <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
              {/* Text Styling tool */}
                <React.Fragment>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="reverse_freeze" name="option1" value="something"/>
                    <label className="form-check-label">Freeze not selected region</label>
                  </div>
                </React.Fragment>
              </div>
            }
            {selectedTool == 'button' &&
              <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
              {/* Text Styling tool */}
              <React.Fragment>
                {/* <span style={{fontSize:'25px', margin:'2rem'}}>Text Styling  </span>  */}
                <Button style={{margin:'2rem'}} onClick={addButtonElement} variant="primary">Add</Button>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    {/* <h6>Preview</h6> */}
                    <div id="button_container" style={{position:'relative'}}>
                      <img src={require(`../assets/${addButtonItemValue}`)} id="button_bg" width="100%"/>
                      <div id="text_container" style={{position:'absolute',top:'50%',left:'50%',transform: 'translate(-50%, -50%)',width:'100%'}}><span style={textStyle} id="text-preview2">{inputText}</span></div>
                    </div>
                    
                    
                    <br/>
                    
                    <TextField id="text-input2" value={inputText} onChange={handleTextChange} label="Text" variant="standard" />
                    <div style={{height: '100%',maxHeight: window.innerHeight*0.5}}>
                      {/* <Item>xs=4</Item> */}
                      <React.Fragment>
                      <FormControl fullWidth>
                      <InputLabel id="addSelect">Add</InputLabel>
                      <Select
                        labelId="add-select-label"
                        id="add-select"
                        value={addType}
                        defaultValue="button"
                        label="Add"
                        onChange={handleSelectChange}
                      >
                        <MenuItem value={'button'}>Button</MenuItem>
                        <MenuItem value={'callout'}>Callout</MenuItem>
                      </Select>
                    </FormControl>
                      </React.Fragment>
                      <div style={{overflowY:'scroll',height: '100%',maxHeight: window.innerHeight*0.3}}>
                      <React.Fragment >
                        {/* <FormControl> */}
                              {/* <FormLabel id="demo-radio-buttons-group-label">Select</FormLabel> */}
                              
                              <div className="d-flex flex-column">
                                {addType == 'button' && buttonPack2}
                                {addType == 'callout' && calloutPack2}
                                </div>
                          {/* </FormControl> */}
                      </React.Fragment>
                      </div>
                      </div>
                    <div style={{overflowY:'scroll',maxHeight: window.innerHeight*0.6}}>
                    <TextField
                      id="outlined-select-font2"
                      name="fontFamily"
                      select
                      label="Font Family"
                      value={textStyle.fontFamily}
                      onChange={handleStyleChange}
                    >
                      {fonts.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="font-size2"
                      name="fontSize"
                      label="Font size in px"
                      value={textStyle.fontSize}
                      onChange={handleStyleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="letter-spacing2"
                      name="letterSpacing"
                      label="Letter spacing in px"
                      value={textStyle.letterSpacing}
                      onChange={handleStyleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="font-weight-select-font2"
                      name="fontWeight"
                      select
                      label="Font weight"
                      value={textStyle.fontWeight}
                      onChange={handleStyleChange}
                    >
                      {fontweight.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="text-decoration-select-font2"
                      name="textDecorationLine"
                      select
                      label="Text decoration"
                      value={textStyle.textDecorationLine}
                      onChange={handleStyleChange}
                    >
                      {textdecoration.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="font-style-select-font2"
                      name="fontStyle"
                      select
                      label="Font Style"
                      value={textStyle.fontStyle}
                      onChange={handleStyleChange}
                    >
                      {fontstyle.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <h6>Text Color</h6>
                    <SketchPicker 
                    disableAlpha={true}
                    color={textStyle.color}
                    onChangeComplete={handleColorChange}/>
                    <br/>
                    <h6>Background Color</h6>
                    <SketchPicker
                    color={bgColor}
                    onChangeComplete={handleBackgroundColorChange}/>
                    </div>
                    
                    
                  </div>
                  </Box>
              </React.Fragment>
            </div>
            }
            {selectedTool == 'add' && 
            <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
            {/* <Item>xs=4</Item> */}
            <h5 style={{margin:'1rem'}}>Add Shapes</h5>
            <React.Fragment>
            <FormControl fullWidth>
            <InputLabel id="addSelect">Add</InputLabel>
            <Select
              labelId="add-select-label"
              id="add-select"
              value={addType}
              defaultValue="button"
              label="Add"
              onChange={handleSelectChange}
            >
              <MenuItem value={'button'}>Button</MenuItem>
              <MenuItem value={'callout'}>Callout</MenuItem>
              <MenuItem value={'icons'}>Icons</MenuItem>
              <MenuItem value={'social'}>Social Media</MenuItem>
              <MenuItem value={'rect'}>Rectangle</MenuItem>
              <MenuItem value={'circle'}>Circle</MenuItem>
            </Select>
          </FormControl>
            </React.Fragment>
            <div style={{overflowY:'scroll',height: '100%',maxHeight: window.innerHeight*0.7}}>
            <React.Fragment >
              {/* <FormControl> */}
                    {/* <FormLabel id="demo-radio-buttons-group-label">Select</FormLabel> */}
                    
                    <div className="d-flex flex-column">
                      {addType == 'icons' && iconPack}
                      {addType == 'button' && buttonPack}
                      {addType == 'callout' && calloutPack}
                      {addType == 'social' && socialPack}
                      </div>
                {/* </FormControl> */}
            </React.Fragment>
            </div>
            </div> }
            {selectedTool == 'text' && 
            <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
              {/* Text Styling tool */}
              <React.Fragment>
                {/* <span style={{fontSize:'25px', margin:'2rem'}}>Text Styling  </span>  */}
                <Button style={{margin:'2rem'}} onClick={addTextElement} variant="primary">Add Text</Button>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    {/* <h6>Preview</h6> */}
                    <div style={{overflow:'scroll'}}><span style={textStyle} id="text-preview">{inputText}</span></div>
                    
                    <br/>
                    <TextField id="text-input" value={inputText} onChange={handleTextChange} label="Text" variant="standard" />
                    <div style={{overflowY:'scroll',maxHeight: window.innerHeight*0.6}}>
                    <TextField
                      id="outlined-select-font"
                      name="fontFamily"
                      select
                      label="Font Family"
                      value={textStyle.fontFamily}
                      onChange={handleStyleChange}
                    >
                      {fonts.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="font-size"
                      name="fontSize"
                      label="Font size in px"
                      value={textStyle.fontSize}
                      onChange={handleStyleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="letter-spacing"
                      name="letterSpacing"
                      label="Letter spacing in px"
                      value={textStyle.letterSpacing}
                      onChange={handleStyleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="font-weight-select-font"
                      name="fontWeight"
                      select
                      label="Font weight"
                      value={textStyle.fontWeight}
                      onChange={handleStyleChange}
                    >
                      {fontweight.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="text-decoration-select-font"
                      name="textDecorationLine"
                      select
                      label="Text decoration"
                      value={textStyle.textDecorationLine}
                      onChange={handleStyleChange}
                    >
                      {textdecoration.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="font-style-select-font"
                      name="fontStyle"
                      select
                      label="Font Style"
                      value={textStyle.fontStyle}
                      onChange={handleStyleChange}
                    >
                      {fontstyle.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <h6>Text Color</h6>
                    <SketchPicker 
                    disableAlpha={true}
                    color={textStyle.color}
                    onChangeComplete={handleColorChange}/>
                    <br/>
                    <h6>Background Color</h6>
                    <SketchPicker
                    color={bgColor}
                    onChangeComplete={handleBackgroundColorChange}/>
                    <br/>
                    {/* Animation selector */}
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">Animation</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={animradio}
                        onChange={handleAnimRadioChange}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="None" />
                        <FormControlLabel value="1" control={<Radio />} label="Move Right" />
                        <FormControlLabel value="2" control={<Radio />} label="Move Down" />
                        <FormControlLabel value="3" control={<Radio />} label="Zoom Out" />
                      </RadioGroup>
                    </FormControl>
                    </div>
                    
                    
                  </div>
                  </Box>
              </React.Fragment>
            </div>}
            {selectedTool == "audio" &&
              <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
                {/* Audio Tool */}
                <React.Fragment>
                  <h5 style={{margin:'1rem'}}>Add Audio</h5>
                  <input style={{color:'transparent'}} type="file" accept=".mp3" onChange={onAudioChange} />
                  <br/>
                  {audio && 
                  <audio style={{margin:'1rem'}} controls>
                  <source src={URL.createObjectURL(audio)}/>
                </audio>}
                </React.Fragment>

              </div>
            }
            {selectedTool == "render" &&
              <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
                {/* Audio Tool */}
                <React.Fragment>
                  <h5 style={{margin:'1rem'}}>Render as:</h5>
                  <TextField
                      id="outlined-select-format"
                      name="renderFormat"
                      select
                      label="Format"
                      value={renderFormat}
                      onChange={handleFormatChange}
                    >
                        <MenuItem key="gif" value="gif">
                          GIF
                        </MenuItem>
                        <MenuItem key="mp4" value="mp4">
                          MP4
                        </MenuItem>
                    </TextField>
                    
                    <Button style={{margin: '0.5rem'}} variant="success" onClick={render2}> Render</Button>
                </React.Fragment>

              </div>
            }
          </Grid> }
          </Grid>
          {isMobile &&
          <Dialog
          fullScreen
          open={openTool}
          onClose={handleCloseTool}
          // TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseTool}
                aria-label="close"
              >
                <CloseIcon/>
              </IconButton>
              {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Rendering...
              </Typography> */}
              {/* <Button autoFocus color="inherit" onClick={handleCloseEditor}>
                save
              </Button> */}
            </Toolbar>
          </AppBar>
          {selectedTool == 'add' && 
            <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
            {/* <Item>xs=4</Item> */}
            <h5 style={{margin:'1rem'}}>Add Shapes</h5>
            <React.Fragment>
            <FormControl fullWidth>
            <InputLabel id="addSelect">Add</InputLabel>
            <Select
              labelId="add-select-label"
              id="add-select"
              value={addType}
              defaultValue="button"
              label="Add"
              onChange={handleSelectChange}
            >
              <MenuItem value={'button'}>Button</MenuItem>
              <MenuItem value={'callout'}>Callout</MenuItem>
              <MenuItem value={'icons'}>Icons</MenuItem>
              <MenuItem value={'social'}>Social Media</MenuItem>
              <MenuItem value={'rect'}>Rectangle</MenuItem>
              <MenuItem value={'circle'}>Circle</MenuItem>
            </Select>
          </FormControl>
            </React.Fragment>
            <div style={{overflowY:'scroll',height: '100%',maxHeight: window.innerHeight*0.7}}>
            <React.Fragment >
              {/* <FormControl> */}
                    {/* <FormLabel id="demo-radio-buttons-group-label">Select</FormLabel> */}
                    
                    <div className="d-flex flex-column">
                      {addType == 'icons' && iconPack}
                      {addType == 'button' && buttonPack}
                      {addType == 'callout' && calloutPack}
                      </div>
                {/* </FormControl> */}
            </React.Fragment>
            </div>
            </div> }
            {selectedTool == 'text' && 
            <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
              {/* Text Styling tool */}
              <React.Fragment>
                {/* <span style={{fontSize:'25px', margin:'2rem'}}>Text Styling  </span>  */}
                <Button style={{margin:'2rem'}} onClick={addTextElement} variant="primary">Add Text</Button>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    {/* <h6>Preview</h6> */}
                    <div style={{overflow:'scroll'}}><span style={textStyle} id="text-preview">{inputText}</span></div>
                    
                    <br/>
                    <TextField id="text-input" value={inputText} onChange={handleTextChange} label="Text" variant="standard" />
                    <div style={{overflowY:'scroll',maxHeight: window.innerHeight*0.6}}>
                    <TextField
                      id="outlined-select-font"
                      name="fontFamily"
                      select
                      label="Font Family"
                      value={textStyle.fontFamily}
                      onChange={handleStyleChange}
                    >
                      {fonts.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="font-size"
                      name="fontSize"
                      label="Font size in px"
                      value={textStyle.fontSize}
                      onChange={handleStyleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="letter-spacing"
                      name="letterSpacing"
                      label="Letter spacing in px"
                      value={textStyle.letterSpacing}
                      onChange={handleStyleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="font-weight-select-font"
                      name="fontWeight"
                      select
                      label="Font weight"
                      value={textStyle.fontWeight}
                      onChange={handleStyleChange}
                    >
                      {fontweight.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="text-decoration-select-font"
                      name="textDecorationLine"
                      select
                      label="Text decoration"
                      value={textStyle.textDecorationLine}
                      onChange={handleStyleChange}
                    >
                      {textdecoration.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="font-style-select-font"
                      name="fontStyle"
                      select
                      label="Font Style"
                      value={textStyle.fontStyle}
                      onChange={handleStyleChange}
                    >
                      {fontstyle.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <h6>Text Color</h6>
                    <SketchPicker 
                    disableAlpha={true}
                    color={textStyle.color}
                    onChangeComplete={handleColorChange}/>
                    <br/>
                    <h6>Background Color</h6>
                    <SketchPicker
                    color={bgColor}
                    onChangeComplete={handleBackgroundColorChange}/>
                    </div>
                    
                    
                  </div>
                  </Box>
              </React.Fragment>
            </div>}
            {selectedTool == "audio" &&
              <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
                {/* Audio Tool */}
                <React.Fragment>
                  <h5 style={{margin:'1rem'}}>Add Audio</h5>
                  <input style={{color:'transparent'}} type="file" accept=".mp3" onChange={onAudioChange} />
                  <br/>
                  {audio && 
                  <audio style={{margin:'1rem'}} controls>
                  <source src={URL.createObjectURL(audio)}/>
                </audio>}
                </React.Fragment>

              </div>
            }
            {selectedTool == "render" &&
              <div style={{height: '100%',maxHeight: window.innerHeight*0.9}}>
                {/* Audio Tool */}
                <React.Fragment>
                  <h5 style={{margin:'1rem'}}>Render as:</h5>
                  <TextField
                      id="outlined-select-format"
                      name="renderFormat"
                      select
                      label="Format"
                      value={renderFormat}
                      onChange={handleFormatChange}
                    >
                        <MenuItem key="gif" value="gif">
                          GIF
                        </MenuItem>
                        <MenuItem key="mp4" value="mp4">
                          MP4
                        </MenuItem>
                    </TextField>
                    
                    <Button style={{margin: '0.5rem'}} variant="success" onClick={render2}> Render</Button>
                </React.Fragment>

              </div>
            }
          </Dialog>
          }
        

        <div id="testdiv" style={{dispay: 'none'}}></div>

        

        {/* Render dialog */}
        <Dialog
        fullScreen
        open={openEditor}
        onClose={handleCloseEditor}
        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseEditor}
              aria-label="close_render"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Rendering...
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleCloseEditor}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <div id="progressbars">
        <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
          <LinearProgress color="secondary" />
          <LinearProgress color="success" />
          <LinearProgress color="inherit" />
        </Stack>
        </div>
        <div className="d-flex" id="render_finish" style={{display:'none',margin:'10px'}}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
        <video id="render_output" controls width="400" height="250" style={{display:'none'}}/>
        <img id="render_output_gif" width="400" height="250" style={{display:'none'}}/>
        <div id="download-btn" style={{display:'none'}}>
        <Button variant="success" onClick={downloadOutput}>Download</Button>
        </div>
        </Grid>
        <Grid item xs={2}>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
      <div>
        {/* <TextField
          id="share-multiline-static"
          label="Share:"
          multiline
          rows={4}
          defaultValue="Your message here"
          value={sharevalue}
          onChange={handleShareChange}
        /> */}
        <div>
          {/* <IconButton onClick={FBshare}>
            <FacebookIcon/>
          </IconButton>
          <IconButton>
            <InstagramIcon/>
          </IconButton>
          <IconButton>
            <YouTubeIcon/>
          </IconButton>
          <IconButton>
            <TwitterIcon/>
          </IconButton>
          <IconButton>
            <WhatsAppIcon/>
          </IconButton> */}
          
        </div>
      </div>
      </Box>
      </Grid>
      </Grid>
      </div>
      {/* <div style={{ backgroundColor: '#242424' }}>
          <Console logs={log} variant="dark" />
        </div>
        <div style={{ backgroundColor: '#242424' }} id="render_console">
          <Console logs={log} variant="dark" />
        </div> */}
        
        </Dialog>

        
      

      <input
        style={{ display: "none" }}
        type="file"
        ref={fileUploadEl}
        onChange={fileChange}
      />

      
    {filterdata()}  
    {/* <div style={{ backgroundColor: '#242424' }}>
          <Console logs={log} variant="dark" />
        </div> */}
    <div id="visualization"></div>
    <TrackContainer fileLen={fileLenghts} files={fileItems} frames={frames} setCurrentT={setCurrentTime2} sendItems={receiveItems} currentT={currentFrameTimeRef}/>
    </div>
  );
}
export default HomePage;