import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

export default function PrivateRoute() {
    const {currentUser} = useAuth()
    // if(currentUser){
    //     if(currentUser.emailVerified){
    //         return <Outlet/>
    //       }else{
    //         //   currentUser.sendEmailVerification()
    //           return <Navigate to="/login"/>
    //       }
    // }else{
    //     return <Navigate to="/login"/>
    // }
    return currentUser?<Outlet/>:<Navigate to="/login"/>  
}
