import React, {useState, useEffect, useRef} from 'react';
import TimeRange from 'react-timeline-range-slider';
import { set } from 'date-fns' 
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';

const AudioTrack = ({loop, currentTime, src, play, videoStopTimeSec, setTimeRange}) =>{

    // console.log(loop, currentTime, src, play)
    
    const [startTimeSec, setStartTimeSec] = useState(0);
    const [stopTimeSec, setStopTimeSec] = useState(videoStopTimeSec);

    var now = new Date()

    const getTodayAtSpecificHour = (hour = 12, minute = 0, second = 0) =>
      set(now, { hours: hour, minutes: minute, seconds: second, milliseconds: 0 })

    const [timelineInterval,setTimelineInterval] = useState([getTodayAtSpecificHour(0,0,0),getTodayAtSpecificHour(1,0,0)])
    const [selectedInterval, setSelectedInterval] = useState([getTodayAtSpecificHour(0,0,0),getTodayAtSpecificHour(0,30,0)]);

    // const audioElement = React.useMemo(() => {
    //     // const element = document.createElement("audio");
    //     // element.loop = loop;
    //     // element.currentTime = currentTime;
    //     // element.src = src;
    //     return document.getElementById('testaudio');
    //   }, []);

    const audioElement = document.getElementById('testaudio');

    // console.log(startTimeSec,stopTimeSec)


      useEffect(()=>{
        // console.log(startTimeSec,stopTimeSec)
        switch(play){
            case 'play': if(audioElement.currentTime >= stopTimeSec || audioElement.currentTime >= startTimeSec + videoStopTimeSec){
                            audioElement.currentTime = startTimeSec;
                        };
                        audioElement.play();
                        break;
            case 'pause': audioElement.pause();
                        break;
            case 'stop': audioElement.pause();
                        audioElement.currentTime = startTimeSec
        }
      },[play])

      useEffect(()=>{
          audioElement.currentTime = startTimeSec+currentTime;
      },[currentTime])

      audioElement.ontimeupdate = (event)=>{
        if (loop){
          if(audioElement.currentTime >= stopTimeSec || audioElement.currentTime >= startTimeSec + videoStopTimeSec){
            audioElement.currentTime = startTimeSec;
            audioElement.play()
          }
        }else{
          if(audioElement.currentTime > stopTimeSec || audioElement.currentTime >= startTimeSec + videoStopTimeSec){
            audioElement.pause();
          }
        }
      }

      React.useEffect(() => {
        const onload = function() {
          const MINUTES = Math.floor(audioElement.duration)
          var m = MINUTES % 60;
          var h = (MINUTES-m)/60;
          setTimelineInterval([getTodayAtSpecificHour(0,0,0),getTodayAtSpecificHour(h,m,0)])
          setSelectedInterval([getTodayAtSpecificHour(0,0,0),getTodayAtSpecificHour(h,m,0)])
          setStopTimeSec(audioElement.duration)
          // console.log('duration',audioElement.duration)
          setTimeRange([startTimeSec,audioElement.duration])
        };
        audioElement.addEventListener("loadedmetadata", onload);
        return () => {
            audioElement.removeEventListener("loadedmetadata", onload);
        };
      }, [audioElement]);

      

      const onChangeCallbackTimeline = (selectedInterval) => {
        setSelectedInterval(selectedInterval);
        var startsec = 0;
        var stopsec = 0;
        if(selectedInterval[0].getHours() > 0){
          startsec = selectedInterval[0].getHours()*60 + selectedInterval[0].getMinutes();
        }else{
          startsec = selectedInterval[0].getMinutes();
        }

        if(selectedInterval[1].getHours() > 0){
          stopsec = selectedInterval[1].getHours()*60 + selectedInterval[1].getMinutes();
        }else{
          stopsec = selectedInterval[1].getMinutes();
        }
        setStartTimeSec(startsec)
        setStopTimeSec(stopsec)
        setTimeRange([startsec,stopsec])
    }

    const startTimeInputOnchange = (e) => {
      setStartTimeSec(e.target.value);
      setTimeRange([e.target.value,stopTimeSec])
      const MINUTES = Math.floor(e.target.value)
      var m = MINUTES % 60;
      var h = (MINUTES-m)/60;
      var selectedEnd = getTodayAtSpecificHour(h,m,0)
      setSelectedInterval([selectedEnd,selectedInterval[1]])
    }

    const stopTimeInputOnchange = (e) => {
      setStopTimeSec(e.target.value);
      setTimeRange([startTimeSec,e.target.value])
      const MINUTES = Math.floor(e.target.value)
      var m = MINUTES % 60;
      var h = (MINUTES-m)/60;
      var selectedEnd = getTodayAtSpecificHour(h,m,0)
      setSelectedInterval([selectedInterval[0],selectedEnd])
    }

    const errorHandlerTimeline = () => {

    }

    return (
      <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <Input name="startTimeInput" value={startTimeSec} onChange={startTimeInputOnchange}  label="Start Time" />
          <Input name="stopTimeInput" value={stopTimeSec} onChange={stopTimeInputOnchange}  label="End Time"  />
        
        {/* <TimeRange
          error={false}  
          ticksNumber={36}
          step={60000}  
          selectedInterval={selectedInterval}  
          timelineInterval={timelineInterval}  
          onUpdateCallback={errorHandlerTimeline}  
          onChangeCallback={onChangeCallbackTimeline}
        //   disabledIntervals={disabledIntervals}  
        /> */}
      </Box>
    )
}

export default AudioTrack;