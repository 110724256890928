import React, { useState } from "react";
import { useRef } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link,useNavigate } from "react-router-dom";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmationRef = useRef();
  const { currentUser,signUp} = useAuth();
  const [error,setError] = useState("")
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit (e){
    e.preventDefault()
    if(passwordRef.current.value !== passwordConfirmationRef.current.value)
    {
      return setError("Passwords don't match")
    }

    try {
      setError("")
      setLoading(true)
      await signUp(emailRef.current.value, passwordRef.current.value)
      navigate("/")
    //   if(currentUser.emailVerified){
    //     navigate("/")
    //   }else{
    //       currentUser.sendEmailVerification()
    //   }
      
    } catch (error) {
      setError("Failed to create an account")
    }

    setLoading(false)
    
  }
  return (
    <>
      <Card style={{ maxWidth: "500px", margin: "0 auto" }}>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" className="text-start">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required></Form.Control>
            </Form.Group>
            <Form.Group id="password" className="text-start">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group id="password-confirm" className="text-start">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmationRef}
                required
              ></Form.Control>
            </Form.Group>
            <Button disabled={loading} className="w-100 mt-2" type="submit">
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </>
  );
}
