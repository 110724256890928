import { Stage, Layer, Image, Rect} from 'react-konva';
import Konva from 'konva';
import React, {useState, useEffect, useRef} from 'react';
import useImage from 'use-image';

let urls = new WeakMap()

let blobUrl = blob => {
  if (urls.has(blob)) {
    return urls.get(blob)
  } else {
    let url = URL.createObjectURL(blob)
    urls.set(blob, url)
    return url
  }
}

const Video = ({ src, start, stop, loop_, currentTime_, play, handler, stageDim_ , mute, playHandler, stageDimHandler}) => {
    const imageRef = React.useRef(null);
    const [size, setSize] = React.useState({ width: 50, height: 50 });
    const [scale,setScale] = useState(1)
    const [currentFrameTimeRef2, setCurrentFrameTimeRef2] = useState(start)

    // let url = src && blobUrl(src)

    // console.log(src)
  
    // we need to use "useMemo" here, so we don't create new video elment on any render
    
    const videoElement = React.useMemo(() => {
      const element = document.createElement("video");
      
      element.loop = loop_;
      element.currentTime = currentTime_;
      
      
      
      return element;
    }, []);

    useEffect(()=>{videoElement.muted = mute},[mute])

    useEffect(()=>{

      // const scaleX = stageDim_[0] / videoElement.videoWidth;
      // const scaleY = stageDim_[1] / videoElement.videoHeight;
      // var scale_ = Math.max(scaleX, scaleY)
      // setScale(scale_)
      
      videoElement.width = videoElement.videoWidth*scale;
      videoElement.height = videoElement.videoHeight*scale;
      // console.log(videoElement.width,videoElement.height,stageDim_,scale)
      setSize({
        width: stageDim_[0]*scale,
        height: stageDim_[1]*scale
      });
    },[stageDim_])

    if(loop_){
        videoElement.loop = loop_
        // videoElement.currentTime = start;
        // videoElement.play()
    }else{
        videoElement.loop = loop_
        // videoElement.currentTime = start;
        // videoElement.play()
    }

    videoElement.ontimeupdate = (event)=>{
      // console.log(videoElement.currentTime)
      handler(videoElement.currentTime)
      setCurrentFrameTimeRef2(videoElement.currentTime)
      if (loop_){
        if(videoElement.currentTime >= stop){
          videoElement.currentTime = start;
        }
      }else{
        if(videoElement.currentTime > stop){
          videoElement.pause();
          playHandler("pause")
        }
      }
    }


    function myHandler(e) {
        // What you want to do after the event
        playHandler("pause")
    }
    videoElement.addEventListener('ended',myHandler,false);

    useEffect(()=>{
      // console.log(play)
      if(play == "play"){
        if(videoElement.currentTime >= stop){
          videoElement.currentTime = start;
        }
        videoElement.play()
      }else if(play == "pause"){
        videoElement.pause()
        videoElement.currentTime = currentFrameTimeRef2
        // console.log('video',currentFrameTimeRef2)
      }else if(play == "stop"){
        videoElement.pause()
        videoElement.currentTime = start
        handler(start);
      }
    },[play])

    useEffect(()=>{
      videoElement.currentTime = currentTime_
      // videoElement.pause()
    },[currentTime_])

    // when video is loaded, we should read it size
    React.useEffect(() => {
      var reader = new FileReader();

      reader.onload = function (e) {
        var source = document.createElement('source');
        source.src = e.target.result;
        source.type = 'video/mp4';
        videoElement.appendChild(source);
      };

      reader.readAsDataURL(src);
      const onload = function() {
        setSize({
          width: videoElement.videoWidth,
          height: videoElement.videoHeight
        });
        const scaleX = stageDim_[0] / videoElement.videoWidth;
        const scaleY = stageDim_[1] / videoElement.videoHeight;
        var scale_ = Math.max(scaleX, scaleY)
        setScale(scale_)
        console.log('stagedim',stageDim_)
        
        // console.log('x y',scaleX,scaleX)
        // console.log('stageDim videoWidth',stageDim_,videoElement.videoWidth,videoElement.videoHeight)
        videoElement.width = parseInt(videoElement.videoWidth*scale_);
        videoElement.height = parseInt(videoElement.videoHeight*scale_);
        console.log('stage dim',parseInt(videoElement.videoWidth*scale_),parseInt(videoElement.videoHeight*scale_),scale_)
        stageDimHandler([parseInt(videoElement.videoWidth*scale_),parseInt(videoElement.videoHeight*scale_)])

        
      };
      videoElement.addEventListener("loadedmetadata", onload);
      return () => {
        videoElement.removeEventListener("loadedmetadata", onload);
      };
    }, [videoElement]);
  
    // use Konva.Animation to redraw a layer
    React.useEffect(() => {
    //   videoElement.play();
      const layer = imageRef.current.getLayer();
      const stage = imageRef.current.getStage();
  
      const anim = new Konva.Animation(() => {}, layer);
      anim.start();
  
      return () => anim.stop();
    }, [videoElement]);
  
    return (
      <Image
        ref={imageRef}
        image={videoElement} 
        // filters={[Konva.Filters.Grayscale]} 
        x={0}
        y={0}
        stroke="red"
        width={videoElement.width}
        height={videoElement.height}
      />
    );
  };

  export default Video;