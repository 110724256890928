import logo from "./logo.svg";
import "./App.css";
import ResponsiveDrawer from "./components/ResponsiveDrawer.js";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Login from "./pages/Login";
import { AuthProvider } from "./contexts/AuthContext";
import Signup from "./pages/Signup";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";
import StartPage from "./StartPage";
import UserVideos from "./pages/UserVideos";

function App() {
  return (
    <div className="App">
      <AuthProvider>
      <Router>
        <ResponsiveDrawer />
        <Routes>
          <Route exact path='/' element={<PrivateRoute/>}>
                <Route exact path='/' element={<StartPage/>}/>
          </Route>
          <Route exact path='/videos' element={<PrivateRoute/>}>
                <Route exact path='/videos' element={<UserVideos/>}/>
          </Route>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
