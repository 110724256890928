import { Resizable } from "re-resizable";
import React, { useState, useRef, useEffect } from "react";
import Waveform from "./Waveform";
import Panel from "./Panel/Panel";
import Timeline from 'react-vis-timeline';
import swal from 'sweetalert2';
import IconButton from '@mui/material/IconButton';
import ContentCutIcon from '@mui/icons-material/ContentCut';

try {
   window.vis = require('vis-timeline/standalone');
} catch (e) {
   console.error(e);
}



export default function App({frames,fileLen,files,sendItems, currentT,setCurrentT}) {
    const [state, setState] = useState({ width: "100%", height: 200 });
    const [timelineRef,setTimeline] = useState();
    var timeline = null;
    var selectedItemId = 0;
    var selectedGroupId = 0;
    var selectedItemStart = '';
    var selectedItemEnd = '';
    var itemList = [];
    var currentTime = 0;

    function callback(params) {
      //  console.log(params)
    }

    function getDate(start, length){
       var date = new Date(1969, 11, 31, 23, 30, 0, 0)
       var startDate = date.setMinutes(date.getMinutes() + start)
       var endDate = date.setMinutes(date.getMinutes() + start + length)
      //  console.log(date.getMinutes(),startDate)
       return [startDate,endDate]
    }

    useEffect(()=>{
      var container = document.getElementById('visualization');

      container.innerHTML = ""

      var start = new Date(1970, 0, 1, 0, 0, 0, 0);
      // var start = new Date("2020-04-13T00:00:00.000+08:00")
      var end = new Date(1970, 0, 1, 1, 0, 0, 0);
      // var end = new Date("2020-04-13T00:00:01.000+08:00")
      console.log(fileLen)
      console.log(files)
      
      var max = end
      
   // Create a DataSet (allows two way data-binding)
   itemList = []
   if (fileLen && files){
      // console.log(files)
   files.forEach((file,idx)=>{
      if(file){
      if (file.type.search("video") > -1){
         max = getDate(0,parseInt(fileLen[file.name]))[1]
         // console.log(max)
         if(isNaN(max)){
            max = end;
         }
      }
      // console.log(fileLen[file.name])
      var grp = 0
      if (file.type.search("audio") > -1){
         grp = 2
      }else{
         grp = 1
      }
      itemList.push({id: idx + 1, content: file.name, start: getDate(0,parseInt(fileLen[file.name]))[0], end:getDate(0,parseInt(fileLen[file.name]))[1], group:grp})
      }
      
   })
   }

   var options = {
      zoomKey: "ctrlKey",
      horizontalScroll: true,
      verticalScroll: true,
      orientation: "top",
      align: 'center',
      moveable: true,
      zoomable : false,
      editable: true,
      showMajorLabels: false,
      min: start,
      // max: max,
      start: start,
      end: max,
      // zoomMax: 10000000,
      // zoomMin: 1000,
      stack:false,
      showCurrentTime: true,
      multiselect: true,
      multiselectPerGroup: true,
      onMove: function (item, callback) {
         // console.log(item);
         var itemListCopy = itemList.slice()
         itemList.forEach((elem,idx) => {
            if (elem.id == item.id){
               var elemCopy = structuredClone(item)
               elemCopy.end = item.end.getTime()
               elemCopy.start = item.start.getTime()
               itemListCopy[idx] = elemCopy
            }
         })
         itemList = itemListCopy
         // console.log(itemListCopy)
         timeline.setItems(new window.vis.DataSet(itemListCopy))
         sendItems(itemList)
       },
       onRemove: function (item, callback) {
         itemList = itemList.filter(obj => {
            return obj.id != item.id
          })
         //  console.log(itemList)
          timeline.setItems(new window.vis.DataSet(itemList))
          sendItems(itemList)
       }
   };

      var groups = [
         {
         id: 1,
         content: 'Video'
         // Optional: a field 'className', 'style', 'order', [properties]
         },
         {
            id: 2,
            content: 'Audio'
            // Optional: a field 'className', 'style', 'order', [properties]
         }
         // more groups...
      ];
   // itemList = [
   //    {id: 1, content: '', start: getDate(0,30)[0], end:getDate(0,30)[1], group:1},
   //    {id: 2, content: '', start: getDate(0,40)[0], end:getDate(0,40)[1], group:2 },
   // ]
   var items = new window.vis.DataSet(itemList);
   sendItems(itemList)
   // console.log(items)

   // Configuration for the Timeline
   // options = {};

   // Create a Timeline
   timeline = new window.vis.Timeline(container, items, options);
   setTimeline(timeline)
   timeline.on('select', function (properties) {
      // console.log('selected items: ' + properties.items);
      selectedItemId = properties.items;
      var result = itemList.filter(obj => {
         return obj.id === selectedItemId
       })
      selectedGroupId = result.group;
      selectedItemStart = result.start;
      selectedItemEnd = result.end;
    });
    timeline.on('timechanged', function (properties) {
      currentTime = properties.time.getTime()- 1800000
      // console.log((currentTime + 21600000)/60000);
      setCurrentT((currentTime + 21600000)/60000);
    });

    timeline.setGroups(groups)
    timeline.addCustomTime(start,1)
   //  console.log(new Date(-21300000))

    function handleCut(){
      var markerTime = timeline.getCustomTime(1).getTime() - 1800000
      // console.log(markerTime)
      var itemListCopy = itemList.slice()
      itemList.forEach((elem,idx) => {
         if (elem.id == selectedItemId){
            
            var elemCopy = structuredClone(elem)
            elem.end = markerTime
            itemListCopy[idx] = elem
            elemCopy.id = itemList.length + 1
            elemCopy.start = markerTime
            itemListCopy.push(elemCopy)
         }
      })
      itemList = itemListCopy
      // console.log(itemListCopy)
      timeline.setItems(new window.vis.DataSet(itemListCopy))
      sendItems(itemList)

   }

   // document.getElementById("cutbtn").onclick = handleCut

    },[files])

    useEffect(()=>{
      if(timelineRef){
         var dat = new Date(1970, 0, 1, 0, 0, 0, 0)
         dat.setMinutes(dat.getMinutes() + currentT)
         // timelineRef.setCustomTime(parseInt(currentT*60000 - 21600000),1)
         timelineRef.setCustomTime(dat,1)
         // console.log(parseInt(currentT*60000 - 21600000))
      }
   },[currentT])

    
   

    
   
    // const waveformRef = useRef();

    // useEffect(() => {
    //     if(waveformRef.current) {
    //         const wavesurfer = Wavesurfer.create({
    //         container: waveformRef.current,
    //         });
    //         waveformRef.current.load("https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3");

    //         waveformRef.current.on("ready", function() {
    //             // https://wavesurfer-js.org/docs/methods.html
    //             waveformRef.current.setVolume(0.5);
    //             waveformRef.current.play();
    //         });
    //     }
    // }, []);
    // DOM element where the Timeline will be attached

  // DOM element where the Timeline will be attached
  

    

    return (
       <div style={{'position':'relative'}}>
          {/* <div id="cutbtn">
          <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
              >
                <ContentCutIcon/>
              </IconButton>
              </div> */}
          
          {/* <Panel title={"video.mp4"} top={"0px"}>
             {frames && <div style={{'position':'absolute','width':'100%','height':'80%','zIndex':'1'}}>
              <img src={frames[0]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[1]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[0]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[1]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
              <img src={frames[0]} style={{'objectFit':'contain','width':'auto','height':'70%','marginTop':'1%'}}/>
            </div>}
          </Panel>
       <Panel title={"audio.mp3"} top={"200px"}>
       <Waveform url={require("./audio.mp3")}/>
     </Panel> */}
     </div>
    );
 }