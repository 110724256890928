import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const app = firebase.initializeApp({
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  apiKey: "AIzaSyAIGTSOZfpvqIJynPBZs0NBosfwMyg0A-I",
  authDomain: "prana-opl.firebaseapp.com",
  databaseURL: "https://prana-opl.firebaseio.com",
  projectId: "prana-opl",
  storageBucket: "prana-opl.appspot.com",
  messagingSenderId: "125513987096",
  appId: "1:125513987096:web:a0aa7436e145e573d83f6d",
  measurementId: "G-CT74SN1F11"
});

export const auth = app.auth();
export default app;
