import React, { useEffect } from "react";
import { Image, Transformer } from "react-konva";
import Konva from 'konva';
import useImage from "use-image";
// import { animated, useSpring } from 'react-spring';
const Img = ({ shapeProps, isSelected, onSelect, onChange, imageUrl, srcType, draggable_ = true, animation = {}, play }) => {
  var shapeRef = React.useRef();
  const trRef = React.useRef();
  const [image] = useImage(imageUrl);
  const [playback,setPlayback] = React.useState(null)

  const isVisible = true;

  const onRefChange = React.useCallback(node => {
    if (node === null) { 
      // DOM node referenced by ref has been unmounted
    } else {
      // DOM node referenced by ref has changed and exists
      shapeRef = node;
      // node.to(
      //   {
      //     opacity:'0%',
      //     duration: 5,
      //   }
      // )
      if (animation.type == 1){
        var tween = new Konva.Tween({
          node: node,
          duration: 2,
          x: 140,
          // y: 90,
          // fill: 'red',
          // rotation: Math.PI ,
          opacity: 1,
          strokeWidth: 6,
          // scaleX: 1.5,
        });
      }
      else if (animation.type == 2){
        var tween = new Konva.Tween({
          node: node,
          duration: 2,
          // x: 140,
          y: 90,
          // fill: 'blue',
          // rotation: Math.PI * 2,
          opacity: 1,
          strokeWidth: 6,
          // scaleX: 1.5,
        });
      }
      else if (animation.type == 3){
        var tween = new Konva.Tween({
          node: node,
          duration: 2,
          // x: 140,
          // y: 90,
          // fill: 'green',
          // opacity: 1,
          // strokeWidth: 6,
          scaleX: 2,
          scaleY: 2,
        });
      }
      

      setPlayback(tween)

      // setTimeout(function () {
      //   tween.play();
      // }, 2000);
    }
  }, []); // adjust deps

  // start tween when played
  if(play === 'play'){
    if(playback){
      playback.play();
    }
  }
  if(play === 'stop'){
    if(playback){
      playback.reset();
    }
  }
  // useEffect(()=>{
  //   setPlayback(play)
  // },[play])

  // const styles = useSpring({
  //   opacity: isVisible ? 1 : 0,
  //   y: isVisible ? 0 : 24
  // })

  let imageScale = 1;
  if(draggable_ && srcType != 'canvas'){
    imageScale = 0.5;
  }
  React.useEffect(() => {
    if (isSelected && draggable_) {
      // we need to attach transformer manually
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  return (
    <React.Fragment>
      
      <Image
        onClick={onSelect}
        image={srcType == 'canvas'?imageUrl:image}
        ref={onRefChange}
        draggable={draggable_}
        scaleX={draggable_?imageScale:1}
        scaleY={draggable_?imageScale:1}
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={e => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: node.width() * scaleX,
            height: node.height() * scaleY,
          });
        }}
      />
      {draggable_ && isSelected && <Transformer ref={trRef} />}
    </React.Fragment>
  );
};
export default Img;