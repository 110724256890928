import React, {useState, useEffect, useRef} from 'react';
import Slider from '@mui/material/Slider';
import { Stage, Layer, Image, Rect} from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import HomePage from "./components/HomePage";
import lion from './assets/lion2.gif'
import './App.css';

import {createFFmpeg, fetchFile} from '@ffmpeg/ffmpeg';
import { Hook, Console, Decode } from 'console-feed';

import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';

const ffmpeg = createFFmpeg({log:true});

function StartPage() {
  console.log('App render')
  const [ready, setReady] = useState(false);
  const [video, setVideo] = useState();
  const [gif, setGif] = useState();
  const [prevTime, setprevTime] = useState([0,100]);
  const [value, setValue] = React.useState([0, 100]);
  const handleVideoMounted = useRef();
  const [log, setLog] = useState();
  const [ffmpegFail, setFfmpegFail] = useState(false);

  // useEffect(()=>{
  //   Hook(window.console, log => {
  //     setLog([...log, Decode(log)]);
  //   })
  // },[])

  

  const convertToTime = (percent) =>{
    const duration = handleVideoMounted.current.duration;
    return duration*(percent*1.00/100.00)
  }

  function getTimeStamps(percentAry){
    return percentAry.map(convertToTime)
  }

  function seek(time){

    if(time[0] !== prevTime[0]){
      handleVideoMounted.current.currentTime = getTimeStamps(time)[0];
      setprevTime(time)
    }
    if(time[1] !== prevTime[1]){
      handleVideoMounted.current.currentTime = getTimeStamps(time)[1];
      setprevTime(time)
    }

    console.log(time + " " + prevTime)
    
    // document.getElementById("videoplayer").currentTime = time;
    
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    seek(newValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  

  const STEP = 0.1;
  const MIN = 0;
  const MAX = 100;
  const rtl = true;

  const load = async () => {
    try{
      await ffmpeg.load();
      setReady(true);
    }catch{
      setFfmpegFail(true)
    }
    
  }

  var stageDim1 = []
  if(window.innerWidth <= 768){
    stageDim1 = [window.innerWidth* 0.9 ,window.innerWidth* 0.9 * 0.5]
  }else{
    stageDim1 = [window.innerWidth* (2/3) * 0.9 ,window.innerWidth* (2/3) * 0.9 * 0.5]
  }
  useEffect(()=>{
    
    load();
  },[]);

  const convertToGif = async () => {
    ffmpeg.FS('writeFile','test.mp4', await fetchFile(video));
    await ffmpeg.run('-i','test.mp4','-ss',`${getTimeStamps(value)[0]}`,'-to',`${getTimeStamps(value)[1]}`,'-f','gif','out.gif');
    const data = ffmpeg.FS('readFile','out.gif');
    const url = URL.createObjectURL(new Blob([data.buffer],{type:"image/gif"}))
    setGif(url)
  }

  const [videoSize, setVideoSize] = React.useState({ width: 50, height: 50 });

  const Video = ({ src }) => {
    const imageRef = React.useRef(null);
    const [size, setSize] = React.useState({ width: 50, height: 50 });
  
    // we need to use "useMemo" here, so we don't create new video elment on any render
    const videoElement = React.useMemo(() => {
      const element = document.createElement("video");
      element.src = src;
      return element;
    }, [src]);
  
    // when video is loaded, we should read it size
    React.useEffect(() => {
      const onload = function() {
        setSize({
          width: videoElement.videoWidth,
          height: videoElement.videoHeight
        });
        setSize({
          width: videoElement.videoWidth,
          height: videoElement.videoHeight
        });
      };
      videoElement.addEventListener("loadedmetadata", onload);
      return () => {
        videoElement.removeEventListener("loadedmetadata", onload);
      };
    }, [videoElement]);
  
    // use Konva.Animation to redraw a layer
    React.useEffect(() => {
      videoElement.play();
      const layer = imageRef.current.getLayer();
      const stage = imageRef.current.getStage();
  
      const anim = new Konva.Animation(() => {}, layer);
      anim.start();
  
      return () => anim.stop();
    }, [videoElement]);
  
    return (
      <Image
        ref={imageRef}
        image={videoElement}
        x={20}
        y={20}
        stroke="red"
        width={size.width/2}
        height={size.height/2}
      />
    );
  };

  // download function
  function downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  return ready || ffmpegFail ?(
    <div className="App">
      
      {/* { video && <div><video 
        id="videoplayer"
        ref={handleVideoMounted}
        controls width="250" 
        //src={URL.createObjectURL(video)}
        >
          <source src={URL.createObjectURL(video)} type="video/mp4" />
        </video>
        <Stage width={window.innerWidth} height={300}>
          <Layer>
            <Video src={URL.createObjectURL(video)}/>
          </Layer>
        </Stage>
        
        </div>} */}

      <canvas id="testcanvas" width="500px" height="300px" style={{display: 'none'}}/>
      <canvas id="testcanvas2" width="500px" height="300px" style={{display: 'none'}}/>
      <video id ="video" width="320" height="240" style={{display: 'none'}}/>
      <audio id="testaudio" style={{display: 'none'}} controls>
        <source id="audiosource" type="audio/mp3"/>
      </audio>
      

      {/* <Slider
        sx={{ margin: '20px', width: '500px' }}
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      /> */}
      {video && <HomePage stageDim1_={stageDim1} ffmpegFail_={ffmpegFail} ffmpeg_={ffmpeg} video_={video} fetchFile_={fetchFile}/>}
      

      <br/>

      {!video && <div style={{margin:'2rem'}}>
      
      <h6>Upload video to continue</h6>
      <br/>
      <input style={{color:'transparent'}} type="file" accept='.mp4' onChange={(e) => setVideo(e.target.files?.item(0))} />
      </div>
    }
      <br/>
        </div>
  ) : (
  <div style={{margin:'2rem'}}>
    <br/>
    <h1>Editor</h1>
    <br/>
    <h6>Loading tools...</h6>
    <br/>
    {/* <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack> */}
    <div className="d-flex justify-content-center">
      <img src={require("./assets/256x256.gif")}/>
    </div>
    <br/>
    {/* <div style={{ backgroundColor: '#242424' }}>
        <Console logs={log} variant="dark" />
      </div> */}
  </div>
  );
}

export default StartPage;
