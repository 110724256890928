import Konva from "konva";
export const addLine = (stage, layer, mode = "brush") => {
  let isPaint = false;
  let lastLine;
  const pointsAry = [];
  stage.on("mousedown touchstart", function(e) {
    isPaint = true;
    let pos = stage.getPointerPosition();
    
    lastLine = new Konva.Line({
      stroke: mode == "brush" ? "red" : "white",
      strokeWidth: mode == "brush" ? 20 : 20,
      globalCompositeOperation:
        mode === "brush" ? "source-over" : "destination-out",
      lineCap: 'round',
      points: [pos.x, pos.y, pos.x, pos.y],
      draggable: false,
    });
    layer.add(lastLine);
  });
  stage.on("mouseup touchend", function() {
    isPaint = false;
    // localStorage.setItem('line-points', JSON.stringify(pointsAry));
    // console.log(pointsAry)
  });
  stage.on("mousemove touchmove", function() {
    if (!isPaint) {
      return ;
    }
  const pos = stage.getPointerPosition();
  // storing circle points
  // var radius = 7
  // pointsAry.push(pos);

  // for(var y = -radius; y<=radius; y++)
  //   for(var x= -radius; x<=radius; x++)
  //       if(x*x+y*y <= radius*radius)
        // pointsAry.push({x: pos.x+x, y: pos.y+y});
  
  let newPoints = lastLine.points().concat([pos.x, pos.y]);
  lastLine.points(newPoints);
  layer.batchDraw();
  });

  var container = stage.container();

  // make it focusable

  container.tabIndex = 1;
  // focus it
  // also stage will be in focus on its click
  container.focus();

  container.addEventListener('keydown', function (e) {
    if (e.keyCode === 88) {// x
      console.log(lastLine.attrs.strokeWidth)
    } else if (e.keyCode === 107) {// +
      lastLine.attrs.strokeWidth = lastLine.attrs.strokeWidth + 2;
    } else if (e.keyCode === 109) {// -
      if(lastLine.attrs.strokeWidth > 2){
        lastLine.attrs.strokeWidth = lastLine.attrs.strokeWidth - 2;
      }
    } else {
      return;
    }
    e.preventDefault();
  });
};